import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Container, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import UserAvatarList from "./components/utils/UserAvatarList";
import TaskList from "./components/TaskList";
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import { useGlobalContext } from "./components/utils/GlobalContextApi";
import { useAuth } from "./components/AuthContext";
import CustomCopyClipboard from "./components/utils/CustomCopyClipboard";
import TiempoFaltante from "./components/utils/TiempoFaltante";
import StatusChip from "./components/utils/StatusChip";
import TaskProgress from "./components/tareas/TaskProgress";
import CustomAvatar from "./components/utils/CustomAvatar";
import CustomModal from "./components/utils/CustomModal";
import UserEditForm from "./components/admin/UserEditForm";
import EditIcon from '@mui/icons-material/Edit';

const UserList = () => {
    const [selectedUserId, setSelectedUserId] = useState();
    const [taskList, setTaskList] = useState([]);
    const [filteredTaskList, setFilteredTaskList] = useState([]);
    const [totalTareas, setTotalTareas] = useState(null);
    const [totalCompletadas, setTotalCompletadas] = useState(null);
    const [totalEnProceso, setTotalEnProceso] = useState(null);
    const [totalIniciadas, setTotalIniciadas] = useState(null);
    const [totalRecibidas, setTotalRecibidas] = useState(null);

    const [infoUserFiltered, setInfoUserFiltered] = useState([]);

    const prid = 0;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const { user } = useAuth();
    const {     
        convertirFecha, 
        totalesTareas, 
        consultarTareas,
        userList,
        // userData
    } = useGlobalContext();
    
    const listaTareas = async () => {
        try {
            const response = await consultarTareas("DESC");
            setTaskList(response.data);
            console.log("TASKLIST USUARIO: ", response.data);
        } catch (error) {
            console.log(error);
        }
    };
    
    const onAvatarSelected = (user) => {
        console.log("USER SELECTED AVATAR tasklist: ", user.id);
        setSelectedUserId(user.id);
        filterTasksByUserId(user.id);
        // handleUserData(user.id); 
    };
    
    const filterTasksByUserId = (userId) => {
        // console.log("Filtering tasks for user ID: ", userId);
        const filteredTasks = taskList && taskList.length > 0 ? taskList.filter(task => task.assignedTo === userId) : [];
        // console.log("Filtered tasks: ", filteredTasks);
        setFilteredTaskList(filteredTasks);
    };

    // const handleUserData = async (uid) => {
    //     try {
    //       const response = await userData(uid);
    //       console.log("USERDATA: ", response);
    //     } catch (error) {
            
    //     }
    // }
    // obtener el total de las tareas desde el global 
    const obtenerCantidadesTareas = async (uid) => {
        try {
        const totalTareasGlobal = await totalesTareas(uid);
        // console.log("CANTIDADES AWAIT: ", totalTareasGlobal.data.Completadas);
        
        setTotalCompletadas(totalTareasGlobal.data.Completadas);
        setTotalRecibidas(totalTareasGlobal.data.Recibidas);
        setTotalEnProceso(totalTareasGlobal.data.EnProceso);
        setTotalIniciadas(totalTareasGlobal.data.Iniciadas);
        setTotalTareas(totalTareasGlobal.data.TotalTareas);

        } catch (error) {
        console.error("Error al actualizar el estado:", error);
        // Manejar el error aquí si es necesario
        }
    }
    
    const filterUserById = async (userIdSelected) => {
        try {
            // Suponiendo que userList es una función que devuelve una lista de usuarios
            const listaUsuarios = await userList(user.id_departamento);
            console.log("LISTA USUARIOS PARA FILTRO INFO USUARIO: ", listaUsuarios);
    
            // Filtrar la lista de usuarios por userIdSelected
            const filteredUsers = listaUsuarios.data.data.filter(user => user.id === userIdSelected);
            console.log("DATA USUARIO FILTRADO: ", filteredUsers);
    
            // Devolver el usuario filtrado o una lista vacía si no se encuentra
            setInfoUserFiltered(filteredUsers.length > 0 ? filteredUsers[0] : null);

            return filteredUsers.length > 0 ? filteredUsers[0] : null;
        } catch (error) {
            console.error('Error al cargar usuarios:', error);
            return null;
        }
    };
    
    useEffect(() => {
        listaTareas();
    }, []);

    useEffect(() => {
        if (selectedUserId) {
            filterTasksByUserId(selectedUserId);
            obtenerCantidadesTareas(selectedUserId);

            filterUserById(selectedUserId);
        }
    }, [selectedUserId]);
    
    const [openModal, setOpenModal] = useState(false);
    const [refreshUserList, setRefreshUserList] = useState(false);
    
    const handleCloseModal = () => {
        setOpenModal(false);
        setRefreshUserList(true);
        filterUserById(infoUserFiltered.id)
        console.log("MODAL CERRADO");
        console.log("refreshUserList:", refreshUserList);
    }

    const actualizarListaUsuarios = () => {
        // Lógica para actualizar la lista de usuarios
        setRefreshUserList(true);
        setTimeout(() => {
            setRefreshUserList(false); // Establece refreshUserList en false después de actualizar la lista de usuarios
        }, 1000); // Espera 1 segundo antes de establecer refreshUserList en false
    };
    
      

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    return (
        <>
        <Container maxWidth="xl">
             <CustomModal open={openModal} onClose={handleCloseModal}>
                <UserEditForm userIdUpdate={infoUserFiltered.id}  handleCloseModal={handleCloseModal}/>
            </CustomModal>
            
            <Typography variant="h2" margin={2}>Usuarios</Typography>
            <Typography variant="h4" margin={2}>Seleccione un usuario para ver el progreso de las tareas</Typography>
            <Stack direction="row" spacing={2} sx={{background: "#f2f2f2", padding: 2, borderRadius:2, marginBottom: 2}}>
                <UserAvatarList
                    labelSearch="Tareas del usuario"
                    avatarSize="smallm"
                    usuarioSeleccionado={selectedUserId}
                    onAvatarSelected={(user, pid) => onAvatarSelected(user, prid)}
                    cantUsuarios={14}
                    maxAncho="1024px"
                    refresh={refreshUserList}
                    actualizarListaUsuarios={actualizarListaUsuarios}
                />
            </Stack>
           
            { selectedUserId && 
                <Box sx={{background: "#f2f2f2", padding: 4, borderRadius:2, marginBottom: 2}}>
                  <Stack  direction="row" spacing={2} sx={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant="h3" sx={{fontSize: 32,  marginBottom: 2}}>
               
                    <Box sx={{marginBottom: 2}}>
                        <Button type="button" 
                            variant="outlined" 
                            onClick={handleOpenModal}>
                            <CustomAvatar 
                                disabled={true}
                                tamano="sm"
                                imagen={baseUrl + infoUserFiltered.imagen}
                                borderColor="#149eca"
                                userId={infoUserFiltered.id} /> 
                            <Typography sx={{marginRight:2}}>Editar Usuario</Typography>
                     </Button>
                

                    </Box>

                        
                        Progreso de  {infoUserFiltered.nombre }
                    </Typography>
                    <Stack  direction="row" spacing={2} sx={{display: "flex", justifyContent: 'flex-end'}}>
                      <StatusChip status="Recibida"  label="Recibidas"/><Typography variant="h3" sx={{fontSize: 24}}>{totalRecibidas}</Typography>
                      <StatusChip status="Iniciada"  label="Iniciadas" /><Typography variant="h3" sx={{fontSize: 24}}>{totalIniciadas}</Typography>
                      <StatusChip status="En Proceso" /><Typography variant="h3" sx={{fontSize: 24}}>{totalEnProceso}</Typography>
                      <StatusChip status="Completada" label="Completadas" /><Typography variant="h3" sx={{fontSize: 24}}>{totalCompletadas  }</Typography>
                    </Stack>
                  </Stack>

                  <Divider sx={{marginBottom: 2}} /> 
                  <TaskProgress
                    completedTasks={totalCompletadas}
                    totalTasks={totalTareas}
                    mensaje="La tarea mas reciente agregada fue: "
                    projectCreationDate={"2023-10-31T12:00:00.000Z"} //cambiar esta fecha por la mas reciente de una tarea
        
                  />
                  </Box>
                }

                  {selectedUserId && filteredTaskList.length > 0 ? 
            <>
                <TableContainer>

                    <Table responsive="sm" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Ticket</TableCell>
                                <TableCell>Departamento</TableCell>
                                <TableCell>Prioridad</TableCell>
                                <TableCell>Descripción</TableCell>
                                <TableCell>Fecha límite</TableCell>
                                <TableCell>Estado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTaskList.map(task => (
                                <TableRow key={task.id}>
                                    <TableCell><CustomCopyClipboard content={ task.ticket }/></TableCell>
                                    <TableCell>{task.departamento}</TableCell>
                                    <TableCell>{task.priority}</TableCell>
                                    <TableCell>{task.description}</TableCell>
                                    <TableCell>
                                        {convertirFecha(task.deadline)}
                                        <TiempoFaltante fechaFinal={task.fechaAsignacion} fechaInicio={task.deadline} />    
                                    </TableCell>
                                    <TableCell>
                                        {task.status == "Recibida" ? (
                                            <StatusChip status="Recibida" />
                                            ) 
                                            : task.status === "Iniciada" ? (
                                            <StatusChip status="Iniciada" />
                                            ) 
                                            : task.status == "En Proceso" ? (
                                            <StatusChip status="En Proceso" />
                                            ) 
                                            : task.status === "Completada" ?
                                            <StatusChip status="Completada" />
                                            : ""
                                        }
                                            
                                      </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> 
                </>
                : 
                <Box>No hay tareas filtradas</Box>
            }
        </Container>
        </>
    )
}

export default UserList;
