import React, { useRef, useState } from 'react';
import copy from 'clipboard-copy';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

const CustomCopyClipboard = ({ content }) => {
  const textRef = useRef(null);
  const [copied, setCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await copy(content);
      setCopied(true);
    } catch (error) {
      console.error('Error al copiar al portapapeles', error);
    }
  };

  return (
    <div>
      <span ref={textRef} style={{ display: 'none' }}>
        {content}
      </span>
      <Tooltip title={copied ? 'Copiado' : 'Copiar: '+ content} arrow placement='top'>
          <div>
            <IconButton onClick={handleCopyClick} disabled={copied} >
              <FileCopyIcon sx={{fontSize: 'medium'}}/>
            </IconButton>
          </div>
      </Tooltip>
    </div>
  );
};

export default CustomCopyClipboard;
