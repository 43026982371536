import React, { useEffect, useState } from "react";
import axios from "axios";
import { Timeline, TimelineItem } from "@mui/lab";
import Typography from "@mui/material/Typography";
import { useGlobalContext } from "./GlobalContextApi";

const SeguimientoTarea = ({ token }) => {
  const [activities, setActivities] = useState([]);

  const { seguimientoTarea } = useGlobalContext();
  useEffect(() => {
    handleSeguimiento();
  }, []);

  const handleSeguimiento = async()=> {
    try {
        const response_seguimiento = await seguimientoTarea (); 

        console.log("RESPONSE SEGUIMIENTO: ", response_seguimiento);
    } catch(error) {
        console.log(error);
    }
  }

  const getActivityStatusMessage = (activityType) => {
    switch (activityType) {
      case "Recibida":
        return "La tarea ha sido recibida.";
      case "Iniciada":
        return "La tarea ha sido iniciada.";
      case "En Proceso":
        return "La tarea está en proceso.";
      case "Completada":
        return "La tarea ha sido completada.";
      default:
        return "";
    }
  };

  return (
    <div>
      <Typography variant="h6">Seguimiento de Tareas</Typography>
      <Timeline>
        {activities.map((activity) => (
          <TimelineItem key={activity.id}>
            <Typography variant="body2" color="textSecondary">
              {new Date(activity.fecha).toLocaleString()}
            </Typography>
            <Typography variant="body1">
              {activity.detalle_actividad}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {getActivityStatusMessage(activity.tipo_actividad)}
            </Typography>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};

export default SeguimientoTarea;
