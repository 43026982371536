import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CustomAvatar from './utils/CustomAvatar';
import { useGlobalContext } from './utils/GlobalContextApi';

const CommentList = ({ commentObject }) => {
  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { userData, convertirFecha } = useGlobalContext();

  // const [avatarData, setAvatarData] = useState({});

  useEffect(() => {
    const fetchUserAvatarsAndUsernames = async () => {
      try {
        const avatarAndUsernameDataMap = {};

        for (const comentario of commentObject) {
          
          const userDataResponse = await userData(comentario.id_usuario);
          // console.log("USER_DATA_RESPONSE", userDataResponse);
          // console.log("COMENTARIO_ID_USUARIO", comentario.id_usuario)
          if (userDataResponse.status === 'ok') {
            const userDataU = userDataResponse.data[0];
            // console.log("USER_DATA_RESPONSE", userDataU);

            avatarAndUsernameDataMap[comentario.id_usuario] = {
              avatar: userDataU.imagen,
              username: userDataU.username,
            };
          }
        }

        // setAvatarData(avatarAndUsernameDataMap);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserAvatarsAndUsernames();
  }, [commentObject, userData]);

  // const avatarImage = (id_usuario) => {
  //   const userData = avatarData[id_usuario];
  //   if (userData) {
  //     return {
  //       imagen: userData.avatar ? baseUrl + userData.avatar : '',
  //       username: userData.username || 'Nombre de usuario no disponible',
  //     };
  //   }
  //   return {
  //     imagen: '',
  //     username: 'Invitado',
  //   };
  // };

  return (
    <List
      sx={{
        width: '100%',
        maxHeight: 440,
        position: 'relative',
        overflow: 'auto',
        borderRadius: 8,
        '& ul': { padding: 0 },
        bgcolor: 'background.paper',
      }}
    >
      <Typography variant="h6" padding={2} gutterBottom component="div">
        Comentarios
      </Typography>
      {commentObject.map((comentario) => (
        <div key={comentario.id}>
          <ListItem
            alignItems="flex-start"
            className={comentario.isNew ? 'new-comment' : ''}
          >
            <ListItemAvatar>
              <CustomAvatar
                tamano="small"
                disabled={true}
                imagen={baseUrl + comentario.imagen} 
                userId={comentario.id_usuario}
              />
            </ListItemAvatar>
            <ListItemText
              primary={comentario.comentario}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="subtitle2"
                    color="text.primary"
                    mr={1}
                  >
                    {comentario.nombre} |
                  </Typography>
                  {convertirFecha(comentario.hora)}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="div" />
        </div>
      ))}
    </List>
  );
};

export default CommentList;
