import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useGlobalContext } from './GlobalContextApi';
import { useAuth } from '../AuthContext';

const CambiarEstatusTarea = ({ 
  taskId, 
  completada, 
  status, 
  onToggleCompletada, 
  mensaje, 
  iconType, 
  onActualizado, 
  disabledTarea, 
  projectId }) => {
    
  const [hovered, setHovered] = useState(false);
  const [idTareaCompletada, setIdTareaCompletada] = useState(null);
  // const [disabledTarea, setDisabledTarea] = useState(false);

  // funciones globales context
  const {
    tareas,
    completedTasks,
    actualizarEstatus,
    agregarTarea,
    consultarTareas,
    guardarActividadTarea,
  } = useGlobalContext();

  const {
    user
  } = useAuth();
  // COMPLETAR TAREA
  const handleCambiarStatus = async () => {
    let cambiarEstado = status  ;
    // ACTUALIZAR LA TABLA DE ACTIVIDAD DE LA TAREA
    if(status === "Recibida") {
      cambiarEstado = "Iniciada"; 
    } else if(status === "Iniciada") {
      cambiarEstado = "En Proceso";

    } else if(status === "En Proceso") {
      cambiarEstado = "Completada";
    }

    try {
      const updateStatus = await actualizarEstatus(taskId, cambiarEstado, user.id, projectId);
      // console.log("updateStatus: ", updateStatus);
      console.log("taskId: " + taskId + " cambiarEstado: " + cambiarEstado + " user.id: " + user.id + " projectId: " + projectId );
      console.log("updateSTATUS: " , updateStatus);
      
      // guardamos la actividad de la tarea
      guardarActividadTarea(4, user.id, taskId, projectId ? projectId : 0);
      
      if (updateStatus.statusCode == 1) {
        console.log("COMPLETADA: ", updateStatus.data);
        onActualizado(updateStatus.statusCode); // Llama a la función para pasar el valor
        
      
        } else if (updateStatus.statusCode == 2) {
        onActualizado(updateStatus.statusCode); // Llama a la función para pasar el valor
        console.log("ACTUALIZADA: ", updateStatus.data);
          
      } else {
        console.log("NO ACTUALIZO: ", updateStatus.data);
      }

    } catch (error) {
      console.error("Error al actualizar el estado:", error);
      // Manejar el error aquí si es necesario
    }
    
    if(idTareaCompletada === taskId) {
      setIdTareaCompletada(null);
      
    } else {
      // console.log("COMPLETADA: ", id)
      setIdTareaCompletada(taskId);

    }

  };


  return (
    <Tooltip  title={!completada ? mensaje : ""} placement='top' arrow>
        <span>
      <IconButton
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleCambiarStatus}
        style={{ color: completada ? 'green' : 'inherit' }}
        disabled={status === "Recibida" && disabledTarea}
      >
        {completada ? (
          <CheckCircleIcon />
        ) : (
          <ArrowCircleRightIcon sx={{color: iconType == 1 ? "#dfab5c" : disabledTarea ? "#cccccc" : "#77b7d7"}} />
        )}
      </IconButton>
      </span>
    </Tooltip>
  );
};

export default CambiarEstatusTarea;
