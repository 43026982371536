import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Grid, IconButton, Stack, Divider } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useGlobalContext } from '../utils/GlobalContextApi';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import dayjs from 'dayjs';
import { Close } from '@mui/icons-material';
import CustomSelect from '../utils/CustomSelect';
import CustomQuillEditor from '../utils/CustomQuillEditor';
import UserAvatarList from '../utils/UserAvatarList';
import CustomDateTimePicker from '../utils/CustomDateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const CreateProject = ({ userId, onCloseModal, onSuccess }) => {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectDeadline, setProjectDeadline] = useState('');
  const { user, logout } = useAuth();
  const { crearProyecto } = useGlobalContext();
  
  const [responsable, setResponsable] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(0);

  const [selectedDate, setSelectedDate] = useState(() => {
    const fechaActual = dayjs();
    let fecha = fechaActual.add(1, 'day');  
    return fecha; 
  });
  const [projectDepartamento, setProjectDepartamento] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [success, setSuccess] = useState(false);

  const handleDateChange = (newDate) => {
    setProjectDeadline(newDate);
    console.log("NEWDATE", newDate);
  };

  const formattedDate = dayjs(projectDeadline).format('YYYY-MM-DD HH:mm:ss');

  const handleAddProject = async () => {
    try {
      // const userIdAsInt = parseInt(userId, 10); // Base 10 para evitar problemas de conversión

      // if (isNaN(userIdAsInt)) {
      //   console.error('userId no es un número válido.');
      //   return;
      // }
      console.log("DEADLINE: ", formattedDate);
      const responsableSelected = selectedUserId == null ? "ooooscar" : selectedUserId;
      console.log("USERID: ", responsableSelected);

      const crear_proyecto = await crearProyecto(
        userId,
        projectName, 
        projectDescription, 
        formattedDate, 
        projectDepartamento, 
        responsableSelected
      );

      if(crear_proyecto.status == "OK") {
        console.log(crear_proyecto);
        setProjectName('');
        setProjectDescription('');
        setProjectDeadline('');
        setSelectedDate(null);

        onSuccess(true);
        setSuccess(true);

      } else {
        console.log(crear_proyecto);
      }

      // Llamar a la función onSuccess si es necesario
      if (onSuccess) {
      }
      
    } catch (error) {
      console.error('Error al agregar el proyecto:', error);
    }
  };

  const handleSelect = (value) => {
    setProjectDepartamento(value.id);
    console.log("SELECT: ", value.id);
  }

  const [editorContent, setEditorContent] = useState('');

  const handleContentChange = (newContent) => {
    setProjectDescription(newContent);
  };

  const [selectedUser, setSelectedUser] = useState(false);
  // Función para manejar la selección de avatar en el componente padre
  const onAvatarSelected = (user) => {
    if(user.id) {
      console.log("USER SELECTED AVATAR padre: ", user.id);
      setSelectedUserId(user.id);
    } else {
      setSelectedUserId(0)
    }
    
    // Puedes realizar cualquier acción adicional con el usuario seleccionado aquí
  };

  return (
    <>
      {!success ? 
        <Box >
          <Grid>
            <Stack direction="row"  sx={{
                marginBottom: 5, 
                display: "flex", 
                justifyContent: 'space-between', 
                alignItems: 'center' }}>
              <Typography variant='h2' sx={{ fontSize: 50 }}>Crear Proyecto</Typography>
              <IconButton onClick={() => {onCloseModal()}}>
                <Close sx={{ color: "#1976d2" }} />
              </IconButton>
            </Stack>

              <UserAvatarList 
                labelSearch="Asignar proyecto"
                idDepar
                ento={user.id_departamento} 
                avatarSize="smallm" 
                usuarioSeleccionado={selectedUserId}
                onAvatarSelected={onAvatarSelected} 
                cantUsuarios={5}
                maxAncho="360px"
              />
            </Grid>
            <Grid>
              <TextField
                label="Nombre del Proyecto"
                variant="outlined"
                fullWidth
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="Descripción del Proyecto"
                variant="outlined"
                fullWidth
                minRows={3}
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <CustomSelect 
                label="Departamento o dependencia que pertenece el proyecto" 
                onSelect={handleSelect} 
              />
              <Box sx={{ marginTop: 2, marginBottom: 2}}>
                <CustomDateTimePicker
                  label="Fecha Límite:"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                  selectedDate={projectDeadline} // Pasar el estado como prop
                  onDateChange={handleDateChange}
                  // onClick={(e) => console.log(e.target.value)}
                />
              </Box>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Fecha Límite:
                  </Typography>
                  <StaticDateTimePicker
                    disablePast
                    orientation="landscape"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </Box>
              </LocalizationProvider> */}
            </Grid>
          <Button variant="contained" color="primary" onClick={handleAddProject}>
            Agregar Proyecto
          </Button>
        </Box> 
        : 
        <Box>
          <Typography>El Proyecto se guardó correctamente</Typography>
        </Box>
      }
    </>
  );
};

export default CreateProject;
