
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TaskForm  from "./components/TaskForm";
import TaskList  from "./components/TaskList";
import Navigation  from "./components/Navigation";
import UserRegistrationForm from './components/admin/UserRegistrationForm';
import UserTask from "./components/UserTasks";
import Login from './components/admin/Login';
import UserProfile from './components/admin/UserProfile';
import SolicitarTarea from './components/tareas/SolicitarTarea';
import SeguimientoTarea from './components/utils/SeguimientoTarea';
import SubTask from './components/tareas/SubtTask';
import { AuthProvider, useAuth } from './components/AuthContext';
import { GlobalProvider } from './components/utils/GlobalContextApi';
import { ThemeProvider, CssBaseline } from '@mui/material';
import uasdTheme from './components/utils/uasdTheme';
import { redirect, Navigate } from "react-router-dom";
import UserProjects from './components/Projects';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Usuarios from './components/Usuarios';
import UserList from './UserList';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
  {/* // <React.StrictMode> */}
    {/* <ThemeProvider theme={uasdTheme}> */}
      <CssBaseline />
      <AuthProvider>
      <GlobalProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <App /> {/* Use the main App component */}
        </BrowserRouter>
        </LocalizationProvider>
        </GlobalProvider>

      </AuthProvider>
    {/* </ThemeProvider> */}
  {/* </React.StrictMode> */}
  </>
);

reportWebVitals();

// Define the main App component
function App() {
  const { user } = useAuth(); // Access user data from AuthContext
  if(user == null) {
    redirect("/login");
  
  }
  
  console.log("USER INDEX", user)
  return (
    <Routes>
      { user != null ? (
        <Route path="/" element={<Navigation />}>
           <Route index element={<TaskList />} />
          <Route path="tareas" element={<TaskList />} />
          <Route path="enviar-tarea" element={<SolicitarTarea />} />
          <Route path="proyectos" element={<UserProjects />} />
          <Route path="mis-tareas" element={<UserTask />} />
          <Route path="registro" element={<UserRegistrationForm />} />
          <Route path="perfil-usuario" element={<UserProfile />} />
          <Route path="subtasks" element={<SubTask />} />
          <Route path="iniciar-sesion" element={<Login />} />
          <Route path="solicitar-tarea" element={<SolicitarTarea />} />
          <Route path="usuarios" element={<UserList />} />
          {/* <Route path="consultar-tarea" element={<ConsultarTarea />} /> */}
        </Route>
      ):(
        <Route path="/" element={<Navigation />}>
          <Route path="iniciar-sesion" element={<Login />} />
          <Route path="*" element={<Navigate to="/iniciar-sesion" />} /> 
        </Route>    
      ) 
      }

    </Routes>
  );
}






 