import React, { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';

import { Input, Paper, TextField, TextareaAutosize, Typography } from '@mui/material';
import { FileUploader } from "react-drag-drop-files";

import Container from '@mui/material/Container';

import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/joy/Grid';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { useGlobalContext } from './utils/GlobalContextApi';
import { useAuth } from './AuthContext';

import Box from '@mui/material/Box';
import axios from "axios";  
import Textarea from '@mui/joy/Textarea';

const TaskForm = ({ proyecto }) => {
  const [lastId, setLastId] = useState(0);
  const [options, setOptions] = useState([]);
  const [optionDepartamentos, setOptionDepartamentos] = useState([]);

  const [file, setFile] = useState(null);
  const [idDepartamento, setIdDepartamento] = useState(0);
  const [title, setTitle] = useState('');
  const [fecha, setFecha] = useState('');
  const [archivos, setArchivos] = useState([]);
  const [tipoSolicitud, setTipoSolicitud] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [correElectronico, setCorreoElectronico] = useState('');
  const [tareaGuardada, setTareaGuardada] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const fileTypes = [
    'JPEG', 'JPG', 'PNG', 'GIF', 'DOC', 'DOCX', 'PDF', 'DOC',
    'DOCX', 'XLS', 'XLSX', 'PSD', 'AI', 'PPTX', 'SVG'
  ];

  const {
    listaDepartamentos,
    consultarTareas, 
    tipoRequerimiento,
    guardarActividadTarea

  } = useGlobalContext();

  const { user } = useAuth();


  useEffect(() => {
    fetchDepartamentos();
    fetchLastId();
    fetchTipoRequerimiento();
    // console.log(baseUrl)
  }, []);

  const fetchDepartamentos = async () => {
    try {
      const response = await listaDepartamentos();
      console.log("LISTA_DEPARTAMENTOS GLOBAL: ", response)
      // const response = await axios.get(`${baseUrl}index.php?consulta=departamentos`);
      const data = response.data;
      // console.log("DATA DEPARTAMENTOS: ", data);

      const options_departamentos = data.map(departamento => ({
        id: departamento.id_departamento,
        label: departamento.departamento,
      }));
      setOptionDepartamentos(options_departamentos);
    } catch (error) {
      console.error('Error al cargar departamentos:', error);
    }
  };

  const fetchLastId = async () => {
    try {
      const response = await consultarTareas("DESC");

      // const response = await axios.get(`${baseUrl}index.php?consulta=tasklist_id_desc`);
      const data = response.data;
      setLastId(data.noresult ? 0 : data[0].id || 0);
    } catch (error) {
      console.error('Error al cargar el último ID:', error);
    }
  };

  const fetchTipoRequerimiento = async () => {
    try {
      const response = await tipoRequerimiento();
      // console.log("TIPO REQUERIMIENTO GLOBALS", response);
      // const response = await axios.get(`${baseUrl}/index.php?consulta=tipo_requerimiento`);
      const data = response.data;
      setOptions(data);
    } catch (error) {
      console.error('Error al cargar los tipos de requerimiento:', error);
    }
  };
//      crear_tarea(idDepartamento, fecha, descripcion, title, 'status', archivos, tipoSolicitud, correElectronico)}>

  const crear_tarea = async (idDepartamento, deadline, descripcion, titulo, status, imagen, tipoSolicitud, correo, proy ) => {
    // e.preventDefault();
    const ticketNum = generateTicket(title);

    try {
      console.log("Enviando información... Espere por favor. PROYECTO: " + proy);

      // Datos a enviar
      const data = {
        idDepartamento: idDepartamento,
        deadline: deadline,
        descripcion: descripcion,
        ticket: ticketNum,
        imagen: imagen,
        tiposolicitud: tipoSolicitud,
        correo: correo,
        project: proy == null || proy == undefined ? 0 : proy,
      };

      console.log("DATA ENVIADA: ", data);

      const response = await axios.post(`${baseUrl}/guardar_tarea.php`, JSON.stringify(data));
      console.log("RESPUESTA: ", response);

      if (response.data.STATUS === 'OK') {
          setTareaGuardada(true);
          // actualizar el status aqui
          guardarActividadTarea(1, user.id, 0, proy == null || proy == undefined ? 0 : proy);

      }
    } catch (error) {
      console.error('Error al enviar la información:', error.message);
    }
  };

  const generateTicket = (str) => {
    const palabras = str.split(' ');
    const d = new Date();
    const year = d.getFullYear();
    const palabrasFiltradas = palabras.filter(palabra => palabra.length > 3);
    const primerasLetras = palabrasFiltradas.map(palabra => palabra.charAt(0).toUpperCase());
    const lastIdNumber = parseInt(lastId) + 1;
    const ticketNumber = `${primerasLetras.join('')}${idDepartamento}-${year}-${addLeadingZeros(lastIdNumber)}`;
    return ticketNumber;
  };

  const addLeadingZeros = (number) => {
    let numString = String(number);
    const targetLength = 6;

    if (numString.length < targetLength) {
      const zerosToAdd = targetLength - numString.length;
      numString = '0'.repeat(zerosToAdd) + numString;
    } else if (numString.length > targetLength) {
      numString = numString.slice(1);
    }
    return numString;
  };

  const handleFiles = async (file) => {
    setFile(file);
    const listaArchivo = {};

    for (let i = 0; i < file.length; i++) {
      const filename = file[i].name;
      const extension = filename.split('.').pop();
      listaArchivo[i] = {
        id: i,
        name: filename,
        extension: extension,
      };

      const formData = new FormData();
      formData.append('file', file[i]);
      formData.append('idDepartamento', idDepartamento);

      try {
        const uploadResponse = await axios.post(`${baseUrl}/upload_file.php`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        listaArchivo[i].path = uploadResponse.data.filePath;
        setArchivos(JSON.stringify(listaArchivo));
      } catch (error) {
        console.error('Error al cargar el archivo:', error);
      }
    }
  };

  return (
    <Container maxWidth="xl">
      {tareaGuardada ? (
        <div>
          <Typography variant="h3">La solicitud fue enviada correctamente</Typography>
        </div>
      ) : (
        <>
          <Grid xs={5}>
            <h1>Solicitud Proyecto: {proyecto}</h1>
            <p>En este apartado puede realizar su solicitud.</p>

            <Autocomplete
              options={options}
              getOptionLabel={option => option.label}
              id="tiposolicitud"
              onChange={(event, newValue) => {
                if (newValue) {
                  setTipoSolicitud(newValue.id);
                }
              }}
              renderInput={params => (
                <TextField {...params} label="Tipo de Solicitud" margin="normal" />
              )}
            />
          </Grid>
          <Grid xs={7}></Grid>
          <form>
            <Autocomplete
              options={optionDepartamentos}
              getOptionLabel={option => option.label}
              id="departamentos"
              onChange={(event, newValue) => {
                if (newValue) {
                  setIdDepartamento(newValue.id);
                  setTitle(newValue.label);
                }
              }}
              renderInput={params => (
                <TextField {...params} label="Departamento o dependencia que solicita" margin="normal" fullWidth />
              )}
            />

            <TextField
              id="outlined-basic"
              label="Correo Electrónico"
              variant="outlined"
              margin="normal"
              onChange={(e) => setCorreoElectronico(e.target.value)}
              fullWidth
            />

            <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker
                          label="Fecha de entrega de la solicitud"
                          onChange={(newValue) => setFecha(newValue)}  fullWidth sx={{ m: 1 }}/>
                      </DemoContainer>
                    </LocalizationProvider>
            </div>

            <Box className="m5" sx={{marginTop: 2}} component="div">

              <Textarea
                margin="normal"
                placeholder="Describa la solicitud…"
                minRows={4}
                maxRows={8}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </Box>

            <Box className="m5" sx={{marginTop: 2}} component="div">
              <FileUploader
                multiple={true}
                handleChange={handleFiles}
                name="file"
                label="Cargue o arrastre los archivos aquí"
                hoverTitle="Deje caer aquí"
                types={fileTypes}
              />
            </Box>
            <p>{file ? `Nombre del archivo: ${file[0].name}` : 'No se han subido archivos'}</p>
            <Button type="button" onClick={() => crear_tarea(idDepartamento, fecha, descripcion, title, 'status', archivos, tipoSolicitud, correElectronico, proyecto)}>
              Solicitar
            </Button>
          </form>
        </>
      )}
    </Container>
  );
};

export default TaskForm;
