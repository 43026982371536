import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const CustomDateTimePicker = ({ label, viewRenderers, selectedDate, onDateChange }) => {
    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        viewRenderers={viewRenderers}
        value={selectedDate} // Establecer el valor seleccionado
        onChange={(newDate) => onDateChange(newDate)} // Llamar a la función de cambio de fecha en el padre
      />
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
