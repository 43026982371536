import React, { useState, useEffect } from 'react';
import { Badge } from "react-bootstrap";

const getColor = (numTareas) => {
  // establecer la escala de colores
  const scale = ['#D3E2CF', '#D0E8CA', '#A4D49C', '#77C370', '#39B54A', '#839F40', '#AC8436', '#CE612C', '#FF0000'];
  const maxTareas = 9;
  
  // asignar color dependiendo de la cantidad de tareas
  if (numTareas == 0) {
     return '#FFFFFF'; // blanco si no tiene tareas
   } else if (numTareas > maxTareas) {
     return scale[maxTareas]; // rojo oscuro si tiene más de 9 tareas
   } else {
    return scale[numTareas-1]; // color intermedio dependiendo del número de tareas\n  
   }
};

const BadgeTareas = ({tareas, keytarea}) => {
  const [tareasUsuarios, setTareasUsuarios] = useState([]);
  return (   
        <div className="badgeTareas" key={keytarea} style={{ backgroundColor: getColor(tareas) }}>
          {tareas}
        </div>
  );
};

export default BadgeTareas;
