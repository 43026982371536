import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Outlet, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { LeftNavDrawer } from './utils/LeftNavDrawer';
import LeftNavDrawer from "./utils/LeftNavDrawer";

import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import { useAuth } from './AuthContext';

import { redirect } from "react-router-dom";
import { MenuBookOutlined } from "@mui/icons-material";

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#1976d2',
//     },
//   },
// });
const settings = ['Perfil', 'Tareas Completadas', 'Logout'];

function ResponsiveAppBar() {

  const { login, user, logout } = useAuth();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState  (null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout();
    setAnchorElUser(null);

  }

  const handleProfile = () => {
    redirect("/profile");

  }


  

  return (
    <>

      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
          <IconButton
              size="large"
              aria-label="menu"
              onClick={() => setIsDrawerOpen(true)}
              color="inherit"
            ><MenuIcon /></IconButton>
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              SMT UASD
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {user ? (

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                  <MenuItem component={Link} to="/tareas"><Typography textAlign="center">TAREAS</Typography></MenuItem>
                  {user.rol_id == 1 && <MenuItem component={Link} to="/enviar-tarea"><Typography textAlign="center">ENVIAR TAREA</Typography></MenuItem>}
                  <MenuItem component={Link} to="/proyectos"><Typography textAlign="center">PROYECTOS</Typography></MenuItem>
                  <MenuItem component={Link} to="/mis-tareas"><Typography textAlign="center">MIS TAREAS</Typography></MenuItem>
                  <MenuItem component={Link} to="/subtasks"><Typography textAlign="center">SUBTASK</Typography></MenuItem>
                  {user.rol_id == 1 && <MenuItem component={Link} to="/registro"><Typography textAlign="center">REGISTRO</Typography></MenuItem>}
                  {user.rol_id == 1 && <MenuItem component={Link} to="/usuarios"><Typography textAlign="center">USUARIOS</Typography></MenuItem>}
              </Menu>
                  
                  ) : (
                    <MenuItem component={Link} to="/iniciar-sesion"><Typography textAlign="center">INICIAR SESION </Typography></MenuItem>
                  )
                }
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>
            

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {user ? (
                  <>
                  {user.rol_id == 1 && <MenuItem component={Link} to="/tareas"><Typography textAlign="center">TAREAS</Typography></MenuItem>}
                  {user.rol_id == 1 && <MenuItem component={Link} to="/enviar-tarea"><Typography textAlign="center">ENVIAR TAREA</Typography></MenuItem>}
                  <MenuItem component={Link} to="/proyectos"><Typography textAlign="center">PROYECTOS</Typography></MenuItem>
                  <MenuItem component={Link} to="/mis-tareas"><Typography textAlign="center">MIS TAREAS</Typography></MenuItem>
                  <MenuItem component={Link} to="/subtasks"><Typography textAlign="center">SUBTASK</Typography></MenuItem>
                  {user.rol_id == 1 && <MenuItem component={Link} to="/usuarios"><Typography textAlign="center">USUARIOS</Typography></MenuItem>}
                  {user.rol_id == 1 && <MenuItem component={Link} to="/registro"><Typography textAlign="center">REGISTRO</Typography></MenuItem>}
               </>
                  ) : (
                <MenuItem component={Link} to="/iniciar-sesion"><Typography textAlign="center">INICIAR SESION</Typography></MenuItem>
                  )}

                </Box>


            <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={user && user.nombre}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user && user.nombre} src={user && baseUrl + user.imagen} />
              </IconButton>
            </Tooltip>
            {user && 
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Cerrar Sesión</Typography>
                </MenuItem>
                <MenuItem>
                <Typography textAlign="center">Tareas Completadas</Typography>
              </MenuItem> 
              <MenuItem component={Link} to="/usuarios">
                <Typography textAlign="center">Perfil</Typography>
              </MenuItem> 

              </Menu>
              }
          </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{ overflow: 'auto', marginTop: 10 }}>
          <Outlet />
        </Box>
        <LeftNavDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    {/* </ThemeProvider> */}

  </>  
  );
}
export default ResponsiveAppBar;
