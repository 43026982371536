import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const CustomAvatar = ({
  tamano = 'medium',
  cantidadTareas,
  imagen,
  borderColor,
  userId,
  userName,
  disabled,
  isSelected,
  onUserSelect,
  onClick,
  badgeColor,
  bw

}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Definir el ancho y alto en función del tamaño
  let w, h;
  let borderSize;
  let bColor = bw ==  false ? 'warning' : borderColor;

  if (tamano === 'small') {
    w = h = 32;
    borderSize = 2;
} else if (tamano === 'smallm') {
    w = h = 48;
    borderSize = 4;

  } else if (tamano === 'medium') {
    w = h = 64;
    borderSize = 4;

  } else if (tamano === 'large') {
    w = h = 96;
    borderSize = 6;

  }

  const handleAvatarClick = () => {
    if (!disabled && onUserSelect) {
      onUserSelect(userId);
    }
  };

  return (
    <>     
        <IconButton
          onClick={() => onClick(userId)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={disabled}
          
        >
        <Badge
           badgeContent={cantidadTareas}
           overlap="circular"
           color="primary"
           anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
           invisible={!cantidadTareas || cantidadTareas == 0 || tamano == "small"}
        >
          <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            borderColor: isSelected || isHovered ? bColor : 'transparent', 
            borderWidth: borderSize,
            borderStyle: 'solid',
            borderRadius: '50%',
            transition: 'border-color 0.3s ease',
            
            }}
            >
            <Avatar
                alt={`User ${userId}`}
                src={imagen}
                sx={{ 
                    width: w, 
                    height: h,
                    filter: disabled && bw == true ? 'grayscale(100%)' : "none" ,
                    opacity: disabled && bw? .5 : 1 
                 }}
                />
            </Box>
        </Badge>

        </IconButton>
        {tamano !== "small" && 
        <Box sx={{textAlign: "center"}}>
            <Typography variant="caption" align="center" sx={{ marginTop: 1 }}>
                  {userName}
            </Typography>
        </Box>
        }
    </>
  );
};

export default CustomAvatar;
