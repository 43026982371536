import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Consulta from '../Consulta';
import TaskForm from '../TaskForm';
import Grid from '@mui/joy/Grid';
import { Paper, Typography } from '@mui/material';
import Container from '@mui/material/Container';


// ESTE ES UN COMPONENTE HELPER, NO SE USA
const SolicitarTarea = () => {
    return (
        <Container maxWidth="xl">
            <Box sx={{minHeight: 300 }}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }} columns={12}>

                    <Grid xs={5}>
                    {/*AGREGAMOS EL COMPONENTE CONSULTA DE LA TAREA
                    QUE CONTIENE EL TIMELINE DE SEGUIMIENTO A LA TAREA */}
                    <Paper elevation={3} sx={{padding: 5, borderRadius: 3}} >
                        <Consulta/ >
                    </Paper>
                    </Grid>
                    <Grid xs={7}>
                    {/* AGREGAMOS EL COMPONENTE QUE CONTIENE EL FORMULARIO */}
                    <TaskForm />               
                    </Grid>
            
                </Grid>
            </Box>
        </Container>
    )
}

export default SolicitarTarea;