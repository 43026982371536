import React from 'react';
import {
  Modal,
  Fade,
  Backdrop,
  Box,
} from '@mui/material';

const CustomModal = ({ open, onClose, children }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            padding: 6,
            minWidth: 300,
            maxHeight: 800, // Establece la altura máxima en 300 píxeles
            overflowY: 'auto', // Agrega scroll vertical cuando el contenido exceda la altura máxima
            outline: 'none',
            borderRadius: 3,
          }}
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;