import React, { useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

const LeftNavDrawer = ({ open, onClose }) => {
  const drawerWidth = 200;

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <List>
        <ListItem component={Link} to="/tareas" onClick={onClose}>
          <ListItemText primary="TAREAS" />
        </ListItem>
        <ListItem component={Link} to="/enviar-tarea" onClick={onClose}>
          <ListItemText primary="ENVIAR TAREA" />
        </ListItem>
        <ListItem component={Link} to="/proyectos" onClick={onClose}>
          <ListItemText primary="PROYECTOS" />
        </ListItem>
        <ListItem
          component={Link}
          to="/tareas-usuario"
          onClick={onClose}
        >
          <ListItemText primary="TAREAS USUARIO" />
        </ListItem>
        <ListItem component={Link} to="/subtasks" onClick={onClose}>
          <ListItemText primary="SUBTASK" />
        </ListItem>
        <ListItem component={Link} to="/registro" onClick={onClose}>
          <ListItemText primary="REGISTRO" />
        </ListItem>
        <ListItem component={Link} to="/solicitar-tarea " onClick={onClose}>
          <ListItemText primary="Solicitar Tareas" />
        </ListItem>
        <ListItem component={Link} to="/seguimiento-tarea " onClick={onClose}>
          <ListItemText primary="Seguimiento Tareas" />
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default LeftNavDrawer;
