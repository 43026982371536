import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { redirect } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [mensajeError, setMensajeError] = useState(null);

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const login = async (email, password) => {
        try {
            const formData = new FormData();
            formData.append('password', password);
            formData.append('email', email);

            const response = await axios.post(baseUrl + 'iniciar_sesion.php', formData);

            console.log("RESPONSE:" , response)
            if (response.data.success === "OK") {
                const userData = {
                    id: response.data.user.id,
                    codigo_empleado: response.data.user.codigo_empleado,
                    username: response.data.user.username,
                    genero: response.data.user.genero,
                    nombre: response.data.user.nombre,
                    correo: response.data.user.correo,
                    id_departamento: response.data.user.id_departamento,
                    imagen: response.data.user.imagen,
                    cargo: response.data.user.cargo,
                    register_date: response.data.user.register_date,
                    rol_id: response.data.user.rol_id,
                    rol: response.data.user.rol,
                };

                // Guardar la información del usuario en una cookie
                document.cookie = `userData=${JSON.stringify(userData)}`;

                setMensajeError(null);
                setUser(userData);
                redirect("/tareas-usuario");

            } else {
                if (response.data.error_code === 1) {
                    setMensajeError(response.data.error);
                }
            }
        } catch (error) {
            console.log("ERROR_CONTEXT: ", error)
            // Manejar errores
        }
    };

    const logout = () => {
        // Eliminar la cookie y limpiar la información del usuario
        document.cookie = 'userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        // if (!user) {
         redirect("/login");
        //   }

        setUser(null);
    };

    

    useEffect(() => {
        // Leer la cookie userData si existe al cargar la página
        const userDataCookie = document.cookie.split('; ').find(row => row.startsWith('userData='));
        if (userDataCookie) {
            const userData = JSON.parse(userDataCookie.split('=')[1]);
            setUser(userData);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
