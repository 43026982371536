import { createTheme } from '@mui/material/styles';
import { FontFamily } from '@mui/joy';

const uasdTheme = createTheme({

      palette: {
        mode: 'dark',
        primary: {
          main: '#79b6f2',
        },
        secondary: {
          main: '#316eff',
        },
        error: {
          main: '#d32f2f',
        },
        warning: {
          main: '#ed6c02',
        },
        info: {
          main: '#0288d1',
        },
        success: {
          main: '#2e7d32',
        },
        divider: 'rgba(162,162,162,0.12)',
      },
      spacing: 8,
      props: {
        MuiTooltip: {
          arrow: true,
        },
        MuiAppBar: {
          color: 'inherit',
        },
        MuiList: {
          dense: true,
        },
        MuiMenuItem: {
          dense: true,
        },
        MuiTable: {
          size: 'small',
        },
        MuiButton: {
          size: 'small',
        },
        MuiButtonGroup: {
          size: 'small',
        },
        MuiCheckbox: {
          size: 'small',
        },
        MuiFab: {
          size: 'small',
        },
        MuiFormControl: {
          margin: 'dense',
          size: 'small',
        },
        MuiFormHelperText: {
          margin: 'dense',
        },
        MuiIconButton: {
          size: 'small',
        },
        MuiInputBase: {
          margin: 'dense',
        },
        MuiInputLabel: {
          margin: 'dense',
        },
        MuiRadio: {
          size: 'small',
        },
        MuiSwitch: {
          size: 'small',
        },
        MuiTextField: {
          margin: 'dense',
          size: 'small',
        },
      },
      overrides: {
        MuiAppBar: {
          colorInherit: {
            backgroundColor: '#689f38',
            color: '#fff',
            radius: 1
          },
        },
      },
      shape: {
        borderRadius: 4,
      },
      typography: {
        fontFamily: 'Montserrat',
        h2: {
          fontFamily: 'Montserrat',
        },
      },
});

export default uasdTheme;

