import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
const { es } = require('date-fns/locale'); // Importar el idioma español
const { parse, format } = require('date-fns');

// Crear el contexto global
const GlobalContext = createContext();


// function insertarActividadTarea($connection, $tipoactividad, $user, $task, $projectId){


// Proveedor del contexto global
export function GlobalProvider({ children }) {
  // Definir el estado global y métodos asociados
  const [tareas, setTareas] = useState([]);

  const BASE_URL = process.env.REACT_APP_BASE_URL + "globals.php";
  const ROOT_URL = process.env.REACT_APP_BASE_URL;

  const [completedTasks, setCompletedTasks] = useState([]);


   // metodo para obtener el total tareas
   const guardarActividadTarea = async (tipoActividad, userId, taskId, prId) => {
      /*
        * TIPOS DE ACTIVIDADES
        * 1 - TAREA CREADA
        * 2 - ASIGNAR TAREA
        * 3 - INSERTAR COMENTARIO
        * 4 - CAMBIO STATUS
        * 5 - CAMBIAR PRIORIDAD
        * 6 - ACTUALIZAR USUARIO
        * 7 - ELIMINAR USUARIO DE TAREA
        * 8 - ELIMINAR TAREA
        * 9 - CAMBIO DE USUARIO
        * 10 - CARGAR ARCHIVO
      */  
      const formData = new FormData();
        formData.append('action', "guardarActividadTarea");
        formData.append('tipoActividad', tipoActividad);
        formData.append('userId', userId);
        formData.append('taskId', taskId);
        formData.append('projectId', prId ? prId : 0 );

        try {
            const response = await axios.post(ROOT_URL + "actions/actividad_tarea.php", formData);
            // const response = await axios.post(BASE_URL, formData);
            // Devuelve la respuesta JSON al componente
            
            console.log("RESPONSE TOTAL TAREAS GLOBAL: ", response.data);
            return response.data;

        } catch (error) {
            console.error('Error al guardar la actividad:', error);
            throw error;
        }
    };

    // metodo para obtener el total tareas
  const listaDepartamentos = async () => {
    const formData = new FormData();
        formData.append('action', "listaDepartamentos");

        try {
            const response = await axios.post(BASE_URL, formData);
            // Devuelve la respuesta JSON al componente
            
            //console.log("RESPONSE TOTAL TAREAS GLOBAL: ", response.data);
            return response.data;

        } catch (error) {
            console.error('Error al actualizar el estado:', error);
            throw error;
        }
    };
  // metodo p
  // metodo para obtener el total tareas
  const totalesTareas = async (userId) => {
    const formData = new FormData();
        formData.append('action', "totalesTareas");
        formData.append('userId', userId);

        try {
            const response = await axios.post(BASE_URL, formData);
            // Devuelve la respuesta JSON al componente
            
            //console.log("RESPONSE TOTAL TAREAS GLOBAL: ", response.data);
            return response.data;

        } catch (error) {
            console.error('Error al actualizar el estado:', error);
            throw error;
        }
    };
  // metodo para crear proyeto
  const crearProyecto = async (userId, proyecto, descripcion, deadline, departamento, responsable) => {
    const formData = new FormData();
        formData.append('action', "crearProyecto");
        formData.append('userId', userId);
        formData.append('proyecto', proyecto);
        formData.append('descripcion', descripcion);
        formData.append('deadline', deadline);
        formData.append('departamento', departamento);
        formData.append('responsable', responsable);

        try {
            const response = await axios.post(BASE_URL, formData);
            // Devuelve la respuesta JSON al componente
            // guardarActividadTarea(4, userId, 0, proyecto);
            
            // console.log("RE5SPONSE TOTAL TAREAS GLOBAL: ", response.data);
            return response.data;

        } catch (error) {
            console.error('Error al actualizar el estado:', error);
            throw error;
        }
    };

    const responsableProyecto = async (userId, projectId) => {
      const formData = new FormData();
          formData.append('action', "agregarResponsable");
          formData.append('userId', userId);
          formData.append('projectId', projectId);
          
          try {
              const response = await axios.post(BASE_URL, formData);
              // Devuelve la respuesta JSON al componente
              console.log("RESPONSE RESPONSABLE: ", response.data);

              if(response.data.status === "OK") {
                guardarActividadTarea(11, userId, 0, projectId);
              }
              return response.data;
          } catch (error) {
              console.error('Error al actualizar el estado:', error);
              throw error;
          }
      };


    //  metodo para actualizar estatus   
    const actualizarEstatus = async (taskId, status, userId, prId) => {


        const formData = new FormData();
        formData.append('action', "actualizarEstatus");
        formData.append('taskId', taskId ? taskId : 0);
        formData.append('status', status);
        formData.append('userId', userId);
        formData.append('projectId', prId ? prId : 0);

        try {
            const response = await axios.post(ROOT_URL + "actions/actualizar_status.php", formData);
            // Devuelve la respuesta JSON al componente
            console.log("RESPUESTA ACTUALIZACION TAREA: ", response.data);
            return response.data;

        } catch (error) {
            console.error('Error al actualizar el ESTADO:', error);
            throw error;
        }
    };


  // Método para agregar una nueva tarea
  const agregarTarea = (newTask) => {
    console.log('Agregando una nueva tarea');
  };
  console.log("BASE URLLLL" , BASE_URL);

  // Método para consultar las tareas
  const consultarTareas = async (orderBy) => {
    const formData = new FormData();
    formData.append('action', "taskList");
    formData.append('order', orderBy);


    try {
        const response = await axios.post(BASE_URL, formData);
        // const response = await axios.post(ROOT_URL + "actions/consultar_tareas.php", formData);
        // Devuelve la respuesta JSON al componente
        return response.data;

    } catch (error) {
        console.error('Error al consultar tareas:', error);
        throw error;
    }
};

  // Método para consultar las tareas
  const tipoRequerimiento = async () => {
    const formData = new FormData();
    formData.append('action', "tipoRequerimiento");

    try {
        const response = await axios.post(BASE_URL, formData);
        // Devuelve la respuesta JSON al componente
        return response.data;

    } catch (error) {
        console.error('Error al actualizar el estado:', error);
        throw error;
    }
};



  // Método para consultar las tareas
  const userAvatar = async (projectId, actionType) => {
    // let idusuario = projectId == 0 ? "userid" : "projectid"; 
  const formData = new FormData();
      formData.append('action', "userAvatar");
      formData.append('projectId', projectId);
      formData.append('userId', projectId);
      formData.append('actionType', actionType);


      try {
          const response = await axios.post(BASE_URL, formData);
          // Devuelve la respuesta JSON al componente
          return response.data;

      } catch (error) {
          console.error('Error al actualizar el estado:', error);
          throw error;
      }
  };

  const convertirFecha = (fechaString) => {
     // Verificar si la cadena de fecha contiene la letra "T" como en el formato ISO 8601
    if (fechaString.includes('T')) {
      // Si contiene "T", parsearla como formato ISO 8601
      const fechaISO8601 = new Date(fechaString);
      // Formatear la fecha en español con hora en formato AM/PM
      const fechaFormateada = format(fechaISO8601, 'EEEE, dd MMM yyyy, hh:mm a', { locale: es });
      return fechaFormateada;
    } else {
      // Si no contiene "T", parsearla como el formato original 'yyyy-MM-dd HH:mm:ss'
      const fecha = parse(fechaString, 'yyyy-MM-dd HH:mm:ss', new Date());
      // Formatear la fecha en español con hora en formato AM/PM
      const fechaFormateada = format(fecha, 'EEEE, dd MMM yyyy, hh:mm a', { locale: es });
      return fechaFormateada;
    }

  }
  
  // metodo para consultar la lista de usuarios
  const userList = async (departamento) => {
    const formData = new FormData();
    formData.append('action', "userList");
    formData.append('departamento', departamento);

    try {
        const response = await axios.post(BASE_URL, formData);
        // Devuelve la respuesta JSON al componente
        // console.log("USERLISTGLOBAL: ", response)
        return response;

    } catch (error) {
        console.error('Error al consultar los usuarios:', error);
        throw error;
    }
  };

    // metodo para consultar la lista de usuarios
    const userData = async (userid) => {
      const formData = new FormData();
      formData.append('action', "userData");
      formData.append('userId', userid);
  
      try {
          // const response = await axios.post(ROOT_URL + "actions/actualizar_status.php", formData);

          const response = await axios.post(ROOT_URL + "actions/user_data.php", formData);
          // Devuelve la respuesta JSON al componente
          return response;
  
      } catch (error) {
          console.error('Error al consultar los usuarios:', error);
          throw error;
      }
    };
  

    // metodo para consultar la lista de usuarios
    const cargosList = async () => {
      const formData = new FormData();
      formData.append('action', "cargos");
  
      try {
          const response = await axios.post(BASE_URL, formData);
          return response;
          // console.log("RES_CARGOS:" , response);
      } catch (error) {
          console.error('Error al consultar los usuarios:', error);
          throw error;
      }
    };
  
    // metodo para remover usuario de la tarea
    const removerUsuarioTarea = async (taskId) => {
      const formData = new FormData();
      formData.append('action', "removerUsuarioTarea");
      formData.append('taskId', taskId);

      try {
          const response = await axios.post(BASE_URL, formData);
          // Devuelve la respuesta JSON al componente
          return response.data;

      } catch (error) {
          console.error('Error al consultar los usuarios:', error);
          throw error;
      }
    };
    
   // metodo para obtener el resultado
   const obtenerResultado = async (taskId) => {
    const formData = new FormData();
    formData.append('action', "obtenerResultado");
    formData.append('taskId', taskId);

    try {
        const response = await axios.post(BASE_URL, formData);
        // Devuelve la respuesta JSON al componente
        return response.data;

    } catch (error) {
        console.error('Error al consultar los usuarios:', error);
        throw error;
    }
  };


  const seguimientoTarea = async(taskId) => {
    const formData = new FormData();
    formData.append('action', "seguimientoTarea");
    formData.append('taskId', taskId);
    
    try {
        const response = await axios.post(BASE_URL, formData);
        // Devuelve la respuesta JSON al componente
        // console.log("RE5SPONSE TOTAL TAREAS GLOBAL: ", response.data);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el estado:', error);
        throw error;
    } 
  }
  return (
    <GlobalContext.Provider
      value={{
        removerUsuarioTarea,
        tareas,
        guardarActividadTarea,
        seguimientoTarea,
        userList,
        userData,
        convertirFecha,
        obtenerResultado,
        crearProyecto,
        responsableProyecto,
        userAvatar,
        totalesTareas,
        completedTasks,
        actualizarEstatus,
        agregarTarea,
        consultarTareas,
        listaDepartamentos,
        tipoRequerimiento,
        cargosList
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

// Función para usar el contexto global
export function useGlobalContext() {
  return useContext(GlobalContext);
}
