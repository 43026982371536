import React, { useState, useEffect, useCallback } from 'react';
import Usuarios from './Usuarios';
import Select from 'react-select';
import UserImage from './UserImage';
import BadgeTareas from "./BadgeTareas";
import axios from "axios";
import Icon from '@mui/material/Icon';
import { TextField, Stack, Switch, Grid } from '@mui/material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/joy/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArticleIcon from '@mui/icons-material/Article';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/joy/Chip';
import Avatar from '@mui/joy/Avatar';
import { css } from '@mui/system';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import CommentForm from './CommentForm';
import CommentList from "./CommentList";
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Check from '@mui/icons-material/Check';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { format } from 'date-fns';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TaskForm from './TaskForm';
import CustomModal from './utils/CustomModal';
import { useAuth } from './AuthContext';
import CustomAvatar from './utils/CustomAvatar';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StatusChip from './utils/StatusChip';
import SearchTask from './utils/SearchTask';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskProgress from './tareas/TaskProgress';
import CambiarEstatusTarea from './utils/CambiarEstatusTarea';
import { useGlobalContext } from './utils/GlobalContextApi';
import CustomCopyClipboard from './utils/CustomCopyClipboard';
import UserAvatarList from './utils/UserAvatarList';
import TiempoFaltante from './utils/TiempoFaltante';

const optionPrioridad = [
  { label: "Alta", value: "Alta" },
  { label: "Media", value: "Media" },
  { label: "Baja", value: "Baja" },
  { label: "Normal", value: "Normal" },
  { label: "RUSH", value: "Rush" }
];




const TaskList = ({ prid }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { login, user, logout } = useAuth();

  const [tasks, setTasks] = useState([]);

  const [filterDepartment, setFilterDepartment] = useState('');
  const [filterPriority, setFilterPriority] = useState('');
  const [users, setUsers] = useState([]);
  const [comment, setComment] = useState('');
  const [italic, setItalic] = useState(false);
  const [fontWeight, setFontWeight] = useState('normal');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [showAssignTaskModal, setShowAssignTaskModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openRowId, setOpenRowId] = useState(null);
  // const [userList, setUserList] = useState([]);
  const [comentario, setComentario] = useState("");
  const [prioridad, setPrioridad] = useState("Normal");
  const [userSelected, setUserSelected] = useState(null);
  const [value, setValue] = useState("Normal");
  const [success, setSuccess] = useState(false);
  const [tareaAsignada, setTareaAsignada] = useState(false);
  const [mensaje, setMensaje] = useState("");
  
  const [totalTareas, setTotalTareas] = useState(null);
  const [totalCompletadas, setTotalCompletadas] = useState(null);
  const [totalEnProceso, setTotalEnProceso] = useState(null);
  const [totalIniciadas, setTotalIniciadas] = useState(null);
  const [totalRecibidas, setTotalRecibidas] = useState(null);

  const [modalTitle, setModalTitle] = useState("");
  const [userAssignedTask, setUserAssignedTask] = useState("");
  const [userIdAssigned, setUserIdAssigned] = useState("");
  const [optionsFilterDepartamentos, setOptionsFilterDepartamentos] = useState([]);
  const [optionsFilterPrioridad, setOptionsFilterPrioridad] = useState([]);
  const [idTareaEliminar, setIdTareaEliminar] = useState("");
  const [modalDelete, setModalDelete] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // CONTEXT

  const [comments, setComments] = useState({});
  const [commentAdded, setCommentAdded] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchComments = async (taskId) => {
    try {
      const response = await axios.get(baseUrl + `consultar_comentarios.php?idtarea=${taskId}`);
      if (response.data.commentsdata && Array.isArray(response.data.commentsdata)) {
        setComments({ ...comments, [taskId]: response.data.commentsdata }); // Cambio aquí
        console.log("COMMENTS.DATA: " , response.data.commentsdata)
        setSuccess(true);
        setLoading(false);
        
      }
    } catch (error) {
      console.error('Error al obtener comentarios:', error);
    }
  };

  useEffect(() => {
      if (commentAdded && openRowId !== null) {
        const fetchData = async () => {
          await fetchComments(openRowId);
        };
        fetchData();
      }
  }, [commentAdded, openRowId]); // Elimina fetchComments de las dependencias

  const handleCommentAdded = () => {
    setCommentAdded(!commentAdded); 
  };

  const  {
    totalesTareas, 
    userAvatar, 
    userList,
    convertirFecha, 
    responsableProyecto, 
    consultarTareas,
    removerUsuarioTarea
  } = useGlobalContext();

  const [searchText, setSearchText] = useState('');

  const [cambiarUsuario, setCambiarUsuario] = useState(true);

  const handleCambiarUsuario = () => {
    setUserIdAssigned(!userIdAssigned);

    // cambiamos el valor del usuario
    setCambiarUsuario(!cambiarUsuario);

    console.log("setCambiarUsuario: ", cambiarUsuario);
    console.log("userIdAssigned: ", userIdAssigned);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  
  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    console.log("CLOSE");
    updateTasks();
    setModalDelete(false);
  };
  const handleShow = () => setShow(true);

  // Esta función está para eliminar los duplicados.
  const filterDuplicates = (arr) => {
    const uniqueValues = [];
    const filteredArray = arr.reduce((acc, item) => {
      if (!uniqueValues.includes(item.value)) {
        uniqueValues.push(item.value);
        acc.push(item);
      }
      return acc;
    }, []);
    return filteredArray;
  };

  // Colocar el color de la tarea de acuerdo a la cantidad que tenga
  const getColor = (numTareas) => {
    const scale = ['#D3E2CF', '#D0E8CA', '#A4D49C', '#77C370', '#39B54A', '#839F40', '#AC8436', '#CE612C', '#FF0000'];
    const maxTareas = 9;

    if (numTareas === 0) {
      return '#1976d2';
    } else if (numTareas > maxTareas) {
      return scale[maxTareas];
    } else {
      return scale[numTareas - 1];
    }
  };

  // Función para obtener el label basado en el value
  const getLabelFromValue = (value) => {
    const foundOption = optionPrioridad.find(option => option.value === value);
    return foundOption ? foundOption.label : "";
  };

  // Actualizar lista de tareas
  const updateTasks = async() => {
    try {
      const response = await consultarTareas("DESC");

      setTasks(response.data);

      if (prid) {
        const tasksData = response.data;
        const filteredTasks = tasksData.filter(task => task.proyecto === prid);
        setTasks(filteredTasks);
      }


    } catch (error) {
      console.log(error)
    }
    // fetch(baseUrl + 'index.php?consulta=tasklist')
    //   .then(response => response.json())
    //   .then(data => setTasks(data));
  }


  // Asignar una tarea a un usuario
  const handleAssignTask = (taskId, userId) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          assignedTo: userId,
          status: 'En progreso'
        };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  // // Mostrar el modal de asignar tarea
  // const handleAssignTaskModal = (taskId, taskName) => {
  //   setSelectedTaskId(taskId);
  //   setShowAssignTaskModal(true);
  //   setShow(true);
  //   setModalTitle(taskName);
  //   userTask(taskId);
  // };

  const [userAssigned, setUserAssigned] = useState([]);

  const userTask = (taskId) => {
    fetch(baseUrl + 'usuario_asignado.php?idTarea=' + taskId)
      .then(response => response.json())
      .then(data => {
        console.log("DATA_ASSIGNED: ", data);
        data[0] === undefined || data[0] === " " ? setUserAssignedTask("La tarea no está asignada!") : setUserAssignedTask(data[0].username);
        data[0] === undefined || data[0] === " " ? setUserIdAssigned("") : setUserIdAssigned(data[0].id);
        data[0] === undefined || data[0] === " " ? setUserAssigned([]) : setUserAssigned(data[0]);
      }
      );
  }

  const handleAssignTaskModalClose = () => {
    setSelectedTaskId(null);
    setShowAssignTaskModal(false);
    setModalDelete(false);
    console.log(modalDelete)
  };

  const handleAssignTaskModalConfirm = (userId) => {
    handleAssignTask(selectedTaskId, userId);
    handleAssignTaskModalClose();
  };
  

  const filteredTasks = tasks && tasks.length > 0 ? tasks.filter((task) => {
    if (filterDepartment && task.id_departamento !== filterDepartment) {
      return false;
    }
    if (filterPriority && task.priority !== filterPriority) {
      return false;
    }
    if (searchText && !task.description.toLowerCase().includes(searchText.toLowerCase())) {
      return false;
    }
    return true;
  }) : [];


  const handleSearch = (searchText) => {
    // Aquí puedes realizar la lógica de búsqueda
    // Por ejemplo, podrías filtrar las tareas según el texto de búsqueda
    const filteredTasks = tasks.filter((task) => {
      if (filterDepartment && task.id_departamento !== filterDepartment) {
        return false;
      }
      if (filterPriority && task.priority !== filterPriority) {
        return false;
      }
      if (searchText && !task.description.toLowerCase().includes(searchText.toLowerCase())) {
        return false;
      }
      return true;
    });
  
    // Actualiza el estado de las tareas filtradas
    setTasks(filteredTasks);
  };
  

  // ...
  
  // <SearchTask onSearch={handleSearch} />
  

  // const cantidad_tareas = (id) => {
  //   const url = baseUrl + "index.php?consulta=cantidad_tareas&userid=" + id;

  //   let tareas = 0;
  //   axios.get(url)
  //     .then(function (response) {
  //       if (response.statusText === "OK") {
  //         tareas = response.data[0].cantidad_tareas;
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   return tareas;
  // }


  const eliminarTarea = (taskid) => {
    // console.log("ELIMIANR TAREA: ", taskid)
    const url = baseUrl + "eliminar_tarea.php?idTarea=" + taskid;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        updateTasks();
        console.log("TAREA ELIMINADA: ", data);
      }
    );
  }

  const handleAgregarTarea = (pid) => {
    setAgregarTarea(true);
    setOpenModal(true);
    setIdTareaEliminar(false);

  }
  const handleModalDelete = (taskId, taskName) => {
      setOpenModal(true);
    // setSelectedTaskId(taskId);
    // setShowAssignTaskModal(true);
    setAgregarTarea(false);
    // setShow(true);
    setModalTitle(taskName);
    // userTask(taskId);
    // setModalDelete(true);
    setIdTareaEliminar(taskId);
  }

  useEffect(() => {

    obtenerCantidadesTareas();
    handleUserAvatarData();

    handleUsuarios();
    fetchTasks();
    // console.log("DATAUSUARIO TL: ", user);
    // fetchUsers();
  }, []);

  const[avatarData, setAvatarData] = useState({});

  
  const handleUserAvatarData = async () => {
    try {
      const avatarDataResponsable = await userAvatar(prid);
      // consolelog(avatarData);
      setAvatarData(avatarDataResponsable.data[0])
      // console.log("USER AVATAR:", avatarDataResponsable.data[0]);
      // console.log("PRID: ", prid);

    } catch (error) {
      console.log(error)
    }
  }




  // obtener el total de las tareas desde el global 
  const obtenerCantidadesTareas = async () => {
    try {
      const totalTareasGlobal = await totalesTareas(0);
      // console.log("CANTIDADES AWAIT: ", totalTareasGlobal.data.Completadas);
      
      setTotalCompletadas(totalTareasGlobal.data.Completadas);
      setTotalRecibidas(totalTareasGlobal.data.Recibidas);
      setTotalEnProceso(totalTareasGlobal.data.EnProceso);
      setTotalIniciadas(totalTareasGlobal.data.Iniciadas);
      setTotalTareas(totalTareasGlobal.data.TotalTareas);

    } catch (error) {
      console.error("Error al actualizar el estado:", error);
      // Manejar el error aquí si es necesario
    }
  }


  // const handleTaskList = async () => {
  //   try {
  //     $taskL = await consultarTareas(id);
  //     console.log($taskL);
  //   } catch (error) {

  //   }
  // }

  const fetchTasks = useCallback(async () => {
    try {
      const response = await consultarTareas("DESC");
      console.log("TASKLIST GLOBALS: ", response);
      if(response.status === "error") {
        console.log("NO HAY TAREAS REGISTRADAS")
        setTasks({taskStatus : "notask"});
      }
      // const response = await axios.get(baseUrl + 'index.php?consulta=tasklist');
      setTasks(response.data);
      
      const totalTareas = totalesTareas(0);
      console.log("COMP: ", totalTareas)

      // console.log("TOTALCOMPLETADASTEST: " , tareasCompletadas)

      // console.log("TASKLIST: ", response)
      if (prid) {
        const tasksData = response.data;
        const filteredTasks = tasksData.filter(task => task.proyecto === prid);
        setTasks(filteredTasks);
      }

      // console.log("response.data.map", response.data)
      const options_filter_departamentos = response.data.map(departamento => ({
        value: departamento.id_departamento,
        label: departamento.departamento
      }));

      let uniqueDeparamentos = filterDuplicates(options_filter_departamentos);
      setOptionsFilterDepartamentos(uniqueDeparamentos);

      const options_filter_prioridad = response.data.map(prioridad => ({
        value: prioridad.priority,
        label: getLabelFromValue(prioridad.priority)
      }));

      let uniquePrioridad = filterDuplicates(options_filter_prioridad);
      setOptionsFilterPrioridad(uniquePrioridad);

      console.log("UNIQUE DEPARTAMENTOS _ ", uniqueDeparamentos)
    } catch (error) {
      setTasks([])
      console.error('Error al obtener tareas:', error);
    }
  }, []);


  
  const handleUsuarios = async () => {
    try {
      // const listaUsuarios = await userList(user.id_departamento);

      const response = await userList(user.id_departamento);
      console.log("USERLIST GLOBALS", response.data.data)
      // const response = await axios.get(baseUrl + `index.php?consulta=usuarios`);
      setUsers(response.data.data);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
    }
  };

  const handleSelectedUser = (idUsuario) => {
    setSelectedAvatar(idUsuario);
    setUserSelected(idUsuario);
    console.log("ASIGNAR TAREA: " + idUsuario);
  }

  const formattedDate = (dateString) => {
    let newDate = format(new Date(dateString), 'dd/MM/yyyy HH:mm:ss');
    return newDate;
 }

  const asignarTarea = useCallback(async (idTarea, comentario, priority, user) => {
    console.log(
      "idTarea: " + idTarea,
      "comentario: " + comentario,
      "prioridad: " + priority,
      "idUsuario: " + user
    
    )
    try {
      const formData = new FormData();
      formData.append('idTarea', idTarea);
      formData.append('comentario', comentario);
      formData.append('prioridad', priority);
      formData.append('user', user);

      const response = await axios.post(baseUrl + 'actualizar_tarea.php', formData);
      console.log("RESPONSE_UPDATE: ", response);

      updateTasks();
      setUserIdAssigned(true);

      if (response.data.success === "OK") {
        setSuccess(true);
        setTareaAsignada(true);
        // handleUsuarios();

        setMensaje("LA TAREA SE ASIGNÓ CORRECTAMENTE");
      } else if (response.data.success === "ERROR") {
        console.log("NO SE PUDO ASIGNAR LA TAREA")
        setSuccess(true);
        setTareaAsignada(true);
      }
    } catch (error) {
      console.error('Error al asignar la tarea:', error);
    }
  }, []);

  const handlePrioridad = (e) =>  {
    console.log("PRIORIDAD: " , e);
    // setValue(e);
    setPrioridad(e);
    setCambiarPrioridad(true);
    // setTask({ ...task, priority: newValue });
  }
  
  const endCollapse = () => {
    console.log("END COLLAPSE");
  }

  const [agregarTarea, setAgregarTarea] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [deshabilitarCampos, setDeshabilitarCampos] = useState(true);

  const handleUserSelect = (userId) => {
    setDeshabilitarCampos(false)
    setSelectedUserId(userId);
    console.log("ID SELECCIONADO USER: ", userId);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false)
    setAgregarTarea(false);
    setIdTareaEliminar(null);
    updateTasks();
  };

  const handleOpenModal = (pid) => {
    setOpenModal(true);
    // console.log("ID SELECCIONADO USER: ", pid);
  }

  const [cambiarPrioridad, setCambiarPrioridad] = useState(false);

  const toggleRow = async (taskId) => {
    setCambiarPrioridad(false);
    userTask(taskId);
    // console.log("userIdAssigned: ", userIdAssigned)
    setSelectedUserId(null);

    if (openRowId === taskId) {
      setOpenRowId(null);
      setDeshabilitarCampos(true);
      // setUserSelected(null);
      // setUserIdAssigned(null);
      setSelectedUserId(null);

      // handleUsuarios();
    } else {
      if (!comments[taskId]) {
        await fetchComments(taskId);
      }
      setOpenRowId(taskId);
    }
  };

  const handleRemoverUsuarioTarea = async (id) => {
    try {
      const response = await removerUsuarioTarea(id);
      console.log("REMOVER USUARIO TAREA GLOBALS: ", response)
      if(response.status === "OK") {
          setTareaAsignada(false);
          setUserIdAssigned(false);
          setSelectedUserId(null);

          handleUsuarios();
          updateTasks();
          console.log("DATA REMOVER USUARIO: " , response);
      }
    } catch(error) {

    }
    // const url = baseUrl + "index.php?consulta=removerusuario&idtarea="+id;
    // fetch(url)
    // .then(response => response.json())
    // .then(data => { 
          
    //       setTareaAsignada(false);
    //       setUserIdAssigned(false);
    //       setSelectedUserId(null);

    //       handleUsuarios();
    //       updateTasks();
    //       // clea


    //       console.log("DATA REMOVER USUARIO: " , data);

    //   }
    // ); 
    // console.log("REMOVER USUARIO: ", id);
  }

  // manejamos el valor que retorna de onactualizado de cambiar status
  // 1 = EL VALOR ES IGUAL AL QUE TENIA
  // 2 = ACTUALIZO
  const handleOnActualizado = (valor) => {
    if(valor == 2 ){
      fetchTasks();
      obtenerCantidadesTareas();
      updateTasks();
      console.log("VALOR ONACTUALIZADO: ", valor);
    }
  };
  // const handleActualizarResponsable = (uid, pid) => {
  //   console.log({uid: uid.id, pid : pid})
  // }
  const handleActualizarResponsable = async (uid, pid) => {
    try {
      const actualizarResponsable = await responsableProyecto(uid.id, pid);
      
      console.log("ACTUALIZAR RESPONSABLE DATA: " , actualizarResponsable);
      if(actualizarResponsable.statusCode == "1") {
        console.log("SE ACTUALIZO EL RESPONSABLE", actualizarResponsable);
        handleUserAvatarData();
      } else {
        console.log("ERROR ACTUALIZANDO RESPONSABLE", actualizarResponsable);
      }
    } catch(error) {
      console.log(error);
    }
  }
  // const [selectedUserId, setSelectedUserId] = useState(null);
  const onAvatarSelected = (user, prid) => {
    const pridd = prid == undefined ? 0 : prid;

    setDeshabilitarCampos(false)
    // setSelectedUserId(userId);

    console.log("USER SELECTED AVATAR tasklist: ", user.id + " PROJECT ID:" + pridd);
    if(user.id) {
      //ATUALIZAR RESPONSABLE
      // handleActualizarResponsable(user.id, prid);
      setSelectedUserId(user.id);
    }
    
  };

  const [switchUser, setSwitchUser] = useState(false);
  const [mostrarListaUsuarios, setMostrarListaUsuarios] = useState(true);
  const handleCambiarResponsable = () => {
    console.log("cambiado");
    setSwitchUser(!switchUser);
    console.log(switchUser);
    setMostrarListaUsuarios(!mostrarListaUsuarios)
  }

  return (
    <>

    {tasks.length === 0 ? (
          <Container maxWidth="xl">
          <Typography variant="h2" sx={{marginBottom: 5, color: prid ? "white" : "black"}}>
            No hay tareas disponibles {prid ? "en este proyecto": ""}.
          </Typography>
        </Container>
      ) : (
    <>
    {/* modal aqui */}
    <CustomModal open={openModal} onClose={handleCloseModal}>
      {idTareaEliminar ? (
      <>
        <Typography variant='h2'>Eliminar Tarea</Typography>
        <Divider sx={{marginTop: 2, marginBottom: 2}} />

        <Typography variant="body2">Está seguro que desea eliminar la tarea:</Typography>
        <Typography variant='h6'>{modalTitle}</Typography>
      
      <Divider sx={{marginTop: 2, marginBottom: 2}} />
      <Stack  direction="row" spacing={2} sx={{display: "flex", justifyContent: 'flex-end'}}>
        <Button
            onClick={()=>{eliminarTarea(idTareaEliminar)}}
            size="sm"
            color='danger'
            variant="soft"
            >ELIMINAR</Button>
        <Button 
            onClick={()=>{handleCloseModal()}}
            size='sm'>CANCELAR</Button>
        </Stack>
      </>
        ) :(
          agregarTarea ? 
          <>
            <TaskForm proyecto={prid} /> 
      <Stack  direction="row" spacing={2} sx={{display: "flex", justifyContent: 'flex-end'}}>

            <Button 
            variant='soft'
              onClick={()=>{handleCloseModal()}}
              size='sm'>CERRAR</Button>
          </Stack>
          </>  
          : "" 
          
        )
      }
    </CustomModal>

          <Container maxWidth="xl">
          {prid ? 
        <Typography variant="h2" sx={{marginBottom: 5, color: "#ffffff", fontSize: 50}}>Tareas</Typography>
        : <Typography variant="h2" sx={{marginBottom: 5}}>Tareas</Typography>
          }
          <Stack direction="row" spacing={2} sx={{background: "#f2f2f2", padding: 2, borderRadius:2, marginBottom: 2}}>
            {!prid && 
              <Stack direction="row" spacing={2} sx={{background: "#f2f2f2", padding: 2, borderRadius:2, marginBottom: 2}}>
                <Box sx={{width: 320}}>
                  <label htmlFor="department-filter">{user.rol_id} Filtrar por departamento:</label>
                  <Autocomplete 
                    key={optionsFilterDepartamentos.map(option => option.id).join('-')} // Agrega la prop key aquí
                    
                    options={optionsFilterDepartamentos} getOptionLabel={option => option.label} id="departamentos"
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        if (newValue) {
                          // colocamos el filtro del valor que seleccionemos (idepartmento)
                          setFilterDepartment(newValue.value);
                        } else {
                          // limpiamos el filtro
                          setFilterDepartment(null)
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Seleccione del departamento" margin="normal" fullWidth />
                      )}
                  />
                </Box>
                        
                <Box sx={{width: 250}}>
                  <label htmlFor="department-filter">Filtrar por Prioridad:</label>
                  <Autocomplete 
                    
                    key={optionsFilterPrioridad.map(option => option.id).join('-')} // Agrega la prop key aquí

                    options={optionsFilterPrioridad} getOptionLabel={option => option.label} id="prioridad"
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        if (newValue) {
                          // colocamos el filtro del valor que seleccionemos (idepartmento)
                          setFilterPriority(newValue.value);
                        } else {
                          // limpiamos el filtro
                          setFilterPriority(null)
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Seleccione una prioridad" margin="normal" fullWidth />
                      )}
                  />
                    </Box>
                    </Stack>
                    
                  }
                      <Grid container spacing={2}>
                        {user.rol_id === 1 &&
                          <Grid item xs={10}>
                            <Stack direction="row" spacing={1} sx={{ marginBottom: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-center' }}>
                                <Tooltip title="Agregar Tarea"  placement="right">
                                <IconButton onClick={() => {handleAgregarTarea(prid)}} >
                                  <AddCircleIcon sx={{color: "#1976d2", fontSize: 50}} />
                                </IconButton>
                                </Tooltip>
                                {avatarData.responsable && prid ? (
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={switchUser}
                                        color="warning"
                                        onChange={() => handleCambiarResponsable()}
                                        aria-label='Cambiar responsable'
                                      />
                                    }
                                    labelPlacement="start"
                                    label="Cambiar responsable"
                                  />
                                ) : null}

                                {(switchUser && avatarData.responsable) || !avatarData.responsable && prid ? (
                          
                              <UserAvatarList
                                    labelSearch="Asignar proyecto"
                                    idDepartamento={user.id_departamento}
                                    avatarSize="smallm"
                                    pid={prid}
                                    usuarioSeleccionado={selectedUserId}
                                    onAvatarSelected={(user, pid) => handleActualizarResponsable(user, prid)}
                                    cantUsuarios={5}
                                    maxAncho="400px"
                                  />
                                ) : null}

                            </Stack>
                          </Grid>
                          }
                        <Grid item xs={2}>
                        {avatarData.responsable && prid ? 
                          <Box sx={{display: "flex", justifyContent: "center" , flexWrap: "nowrap", flexDirection: "column"}}> 
                            <Typography sx={{textAlign: "center"}} variant='subtitle2'> Coordina {avatarData.cantidad_proyectos} {avatarData.cantidad_proyectos == 1 ? "proyecto" : "proyectos" }</Typography>

                              <CustomAvatar
                                tamano={"medium"}
                                cantidadTareas={avatarData.cantidad_tareas} // Proporciona la cantidad de tareas desde el estado
                                imagen={baseUrl + avatarData.imagen}
                                borderColor="#149eca"
                                userId={avatarData.id}
                                disabled={true}
                                userName={avatarData.nombre}
                                bw={false}
                                isSelected={true}
                                // onClick={() => onAvatarSelected(userItem)} // Pasa el objeto de usuario completo
                              />
                            </Box>
                            :  user.rol_id !== 1 ? <Typography variant='h5'>No tiene coordinador asignado</Typography>
                            :  prid && <Typography variant='h5'>Agrega un coordinador del proyecto</Typography>

                          }
                        </Grid>
                      </Grid>
                      
                    
                    </Stack>


                    {!prid && 
                    
                    <Box sx={{background: "#f2f2f2", padding: 4, borderRadius:2, marginBottom: 2}}>
                      <Stack  direction="row" spacing={2} sx={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h2" sx={{fontSize: 32,  marginBottom: 2}}>Progreso de las tareas</Typography>
                        <Stack  direction="row" spacing={2} sx={{display: "flex", justifyContent: 'flex-end'}}>
                          <StatusChip status="Recibida"  label="Recibidas"/><Typography variant="h3" sx={{fontSize: 24}}>{totalRecibidas}</Typography>
                          <StatusChip status="Iniciada"  label="Iniciadas" /><Typography variant="h3" sx={{fontSize: 24}}>{totalIniciadas}</Typography>
                          <StatusChip status="En Proceso" /><Typography variant="h3" sx={{fontSize: 24}}>{totalEnProceso}</Typography>
                          <StatusChip status="Completada" label="Completadas" /><Typography variant="h3" sx={{fontSize: 24}}>{totalCompletadas  }</Typography>
                        </Stack>
                      </Stack>

                      <Divider sx={{marginBottom: 2}} /> 
                      <TaskProgress
                        completedTasks={totalCompletadas}
                        totalTasks={totalTareas}
                        mensaje="La tarea mas reciente agregada fue: "
                        projectCreationDate={"2023-10-31T12:00:00.000Z"} //cambiar esta fecha por la mas reciente de una tarea
            
                      />
                      </Box>}
                      <TableContainer component={Paper}>
                      <Table responsive="sm" stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                          <TableCell></TableCell>

                            <TableCell>Código</TableCell>
                            <TableCell>Departamento</TableCell>
                            <TableCell>Prioridad</TableCell>
                            <TableCell>Descripción</TableCell>
                            <TableCell align='center'>Responsable</TableCell>
                            {user.rol_id == "1" && <TableCell>Acciones</TableCell>}
                            <TableCell>Fecha límite</TableCell>
                            <TableCell>Estado</TableCell>
                            {user.rol_id == "1" && <TableCell>Eliminar</TableCell>}
                            {user.rol_id == "1" && <TableCell>Cambiar Estatus</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredTasks.map((task) => (
                            <React.Fragment key={task.id}>

                            <TableRow key={task.id}>
                              <TableCell>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => toggleRow(task.id)}
                                  >
                                    {openRowId === task.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>
                              
                              <TableCell>{<CustomCopyClipboard content={ task.ticket } />}</TableCell>
                              <TableCell>{task.departamento}</TableCell>
                              <TableCell>{task.priority}</TableCell>
                              <TableCell>{task.description}</TableCell>
                              <TableCell>
                                {task.imagen ? (
                                  <Tooltip title={task.nombre} arrow placement='top'>
                                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <CustomAvatar
                                        tamano="small"
                                        imagen={baseUrl + task.imagen}
                                        borderColor={task.status == "Completada" ? "#5cb85c" : "transparent"}
                                        isSelected={false}
                                        disabled={true}
                                        bw={false}
                                        
                                        /></Box>
                                        </Tooltip>
                                    ) : (
                                      <>
                                        {user.rol_id == "1" &&
                                          <Button
                                            size="sm"
                                            onClick={() => toggleRow(task.id)}
                                          >
                                            Asignar
                                          </Button>
                                        }
                                    </>)}
                              </TableCell>
                            {user.rol_id == "1" &&
                                    <TableCell> 
                                    <Stack direction="row" spacing={1}>
                                        <Tooltip title="Actualizar Usuario" placement="top" arrow>
                                          <Box>
                                            <IconButton disabled={task.status != "Completada" && task.imagen ? false : true} onClick={() => {toggleRow(task.id);}}>
                                                <PublishedWithChangesIcon fontSize="small" />
                                            </IconButton>
                                          </Box>
                                        </Tooltip>
                                        <Tooltip title="Eliminar Usuario de la tarea" placement="top" arrow>
                                          <Box>
                                          <IconButton disabled={task.status != "Completada" && task.imagen ? false : true} onClick={() => {handleRemoverUsuarioTarea(task.id);}}>
                                              <PersonRemoveIcon fontSize="small" />
                                          </IconButton>
                                          </Box>
                                        </Tooltip>
                                    </Stack>
                              </TableCell>
                              }
                              <TableCell>
                                
                                <TiempoFaltante fechaFinal={task.fechaAsignacion} fechaInicio={task.deadline} />

                              </TableCell>
                              <TableCell>
                                {task.status == "Recibida" ? (
                                            <StatusChip status="Recibida" />
                                            ) 
                                            : task.status === "Iniciada" ? (
                                            <StatusChip status="Iniciada" />
                                            ) 
                                            : task.status == "En Proceso" ? (
                                            <StatusChip status="En Proceso" />
                                            ) 
                                            : task.status === "Completada" ?
                                            <StatusChip status="Completada" />
                                            : ""

                                          }
                              </TableCell>
                              {user.rol_id == "1" &&
                              <TableCell>
                                  <IconButton disabled={task.status == "Completada" && task.imagen || task.imagen ? true : false} onClick={() => {handleModalDelete(task.id, task.description);}}>
                                  <Tooltip title="Eliminar Tarea" placement="top" arrow>
                                    {task.status == "Completada" && task.imagen || task.imagen ? 
                                      <DeleteForeverIcon fontSize="small"/> : 
                                      <DeleteIcon fontSize="small"/>}
                                    </Tooltip>
                                  </IconButton>
                              </TableCell>
                              }
                              {user.rol_id == "1" &&
                              <TableCell>
                                <CambiarEstatusTarea
                                    mensaje={
                                      task.status && task.imagen && task.status == "Recibida" ? "INICIAR la Tarea" 
                                      : task.status  && task.imagen && task.status == "Iniciada" ? "Cambiar a Status: EN PROCESO" 
                                      : !task.imagen ? "Debe asignar un usuario para cambiar estatus" 
                                      : "Cambiar a Status: COMPLETADA"
                                    }
                                    onActualizado={handleOnActualizado}
                                    taskId={task.id}
                                    status={task.status}
                                    iconType={task.status == "Iniciada" || task.status == "En Proceso" ?  1 : 2}
                                    completada={task.status == "Completada" ? true : false }
                                    disabledTarea={!task.imagen ? true : false }
                                    projectId={prid == undefined || prid == null ? 0 : prid } 

                                  />
                                </TableCell>
                              }
                            </TableRow>
                            <TableRow sx={{background: "blue"}}>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 , backgroundColor: task.assignedTo ? '#374151' : '#191837' }} colSpan={13}>
                              
                                {/* COLLAPSE LISTA DE USUARIO */}
                                <Collapse 
                                  in={openRowId === task.id} 
                                  timeout="auto"
                                  // addEndListener={endCollapse}
                                  >
                                    {!tareaAsignada &&
                                  <Box sx={{margin: 3}}>
                                    {task.assignedTo ? 
                                      <>
                                      <Typography variant='h6' sx={{margin: 2, color: "#f6f7f9"}}>La tarea fue asignada a:</Typography> 
                                    
                                          <Box sx={{ width: 500 }}>
                                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                              <CustomAvatar
                                                  tamano="large"
                                                  imagen={baseUrl + task.imagen}
                                                  borderColor="#149eca"
                                                  isSelected={true}
                                                  disabled={true}
                                                  bw={false}/>
                                                  
                                                <Box>
                                                  <Typography variant='h6' sx={{margin: 1, color: "#f6f7f9"}}>{task.nombre} {task.status}</Typography> 

                                                  <Typography variant='subtitle2' sx={{margin: 1, color: "#f6f7f9"}}>{task.cargo}</Typography> 
                                                  <Typography variant='subtitle2' sx={{margin: 1, color: "#f6f7f9"}}>{task.departamentoUsuario}</Typography> 
                                                  
                                                </Box>
                                            </Stack>
                                          </Box>

                                          <Box display="flex" mb={3} sx={{ marginTop: 2}}>

                                              <Box flex="1">
                                                <Paper elevation={3} sx={{ borderRadius: 2 }} >
                                                  <CommentList commentObject={comments[task.id] || []} taskId={task.id} />
                                                </Paper>
                                              </Box>
                                              <Box flex="1" ml={2} >
                                                  <CommentForm 
                                                    onSuccess={handleCommentAdded} 
                                                    taskId={task.id} 
                                                    userIdTask={task.assignedTo} 
                                                    projectId={1}
                                                  />
                                              </Box>
                                            </Box>
                                            {task.status == "Recibida" ? (
                                            <FormControlLabel
                                              control={
                                                <Switch 
                                                  checked={!userIdAssigned} 
                                                  color="warning"
                                                  onChange={() => handleCambiarUsuario()} // 
                                                  aria-label='Cambiar de Usuario'/>
                                              }
                                              labelPlacement="start"
                                              label="Cambiar de usuario"
                                            />) : task.status === "Iniciada" ? (
                                              <Alert variant='filled' severity="error">{task.status} Esta tarea se encuentra {task.status}, no se puede realizar cambio de usuario</Alert>
                                            ) : task.status == "En Proceso" ? (
                                              <Alert variant='filled'  severity="warning">{task.status} Esta tarea se encuentra {task.status}, no se puede realizar cambio de usuario</Alert>
                                            ) : task.status === "Completada" ?
                                            <Alert variant='filled' severity="success"> Esta tarea se encuentra {task.status}. - COLOCAR FECHA AQUI DE COMPLETDA</Alert>
                                              : ""

                                          }
                                            
                                      </>
                                      : 
                                      <>
                                      
                                      {/* <Typography variant='h4' sx={{margin: 2, color: "#f6f7f9"}}>Seleccione un usuario para asignar la tarea</Typography> 
                                      {task.assignedTo && 
                                          <FormControlLabel
                                              control={
                                                <Switch 
                                                  color="warning"
                                                  checked={!userIdAssigned} 
                                                  onChange={() => setUserIdAssigned(!userIdAssigned)} // Cambia el estado al hacer clic en el Switch
                                                  aria-label='Cambiar de Usuario'/>
                                              }
                                              labelPlacement="start"
                                              label="Cambiar de usuario"
                                            />} */}
                                      </>
                                      } 

                                <Divider sx={{marginTop: 2, marginBottom: 2}}/>

                                  {openRowId === task.id && !userIdAssigned &&
                                    <FormControl>

                                    {/* <Box sx={{ padding: 1, margin: 1 }} display="flex" flexDirection="row" flexWrap="wrap">
                                      {Object.values(users).map((user) => (
                                        <Box sx={{ margin: 1 }} key={user.id}>
                                          <CustomAvatar
                                              tamano="medium"
                                              cantidadTareas={user.total_tareas}
                                              imagen={baseUrl + user.imagen}
                                              borderColor="#149eca"
                                              userId={user.id}
                                              disabled={userAssigned.id === user.id ? true : false}
                                              userName={user.nombre}
                                              bw={true}

                                              isSelected={userAssigned.id === user.id || selectedUserId === user.id ? true : false}
                                              // onUserSelect={handleUserSelect}
                                              onClick={ handleUserSelect }
                                            />
                                        </Box>
                                      ))}
                                    </Box>; */}

                                      <Box>
                                        <Typography variant="h4" margin={2} color="white">Seleccione un usuario para asignar la tarea</Typography>
                                        <Stack direction="row" spacing={2} sx={{background: "#f2f2f2", padding: 2, borderRadius:2, marginBottom: 2}}>
                                            <UserAvatarList
                                                labelSearch="Tareas del usuario"
                                                avatarSize="smallm"
                                                usuarioSeleccionado={selectedUserId}
                                                onAvatarSelected={(user, pid) => onAvatarSelected(user, prid)}
                                                cantUsuarios={14}
                                                maxAncho="1024px"

                                            />
                                        </Stack>
                                      </Box>

                                      {/* <Divider sx={{marginTop: 2, marginBottom: 3}}/> */}
                                      <Textarea
                                        disabled={deshabilitarCampos}
                                        placeholder="Escribe un comentario para la tarea…"
                                        onChange={(e) => setComentario(e.target.value)}
                                        minRows={3}

                                        endDecorator={
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              gap: 'var(--Textarea-paddingBlock)',
                                              pt: 'var(--Textarea-paddingBlock)',
                                              borderTop: '1px solid',
                                              borderColor: 'divider',
                                              flex: 'auto',
                                            }}
                                          >
                                            <IconButton
                                            disabled={true}
                                              variant="plain"
                                              color="neutral"
                                              onClick={(event) => setAnchorEl(event.currentTarget)}
                                            >
                                              <FormatBold />
                                              <KeyboardArrowDown fontSize="md" />
                                            </IconButton>
                                            <Menu
                                              anchorEl={anchorEl}
                                              open={Boolean(anchorEl)}
                                              onClose={() => setAnchorEl(null)}
                                              size="sm"
                                              placement="bottom-start"
                                              sx={{ '--ListItemDecorator-size': '24px' }}
                                            >
                                              {['200', 'normal', 'bold'].map((weight) => (
                                                <MenuItem
                                                  key={weight}
                                                  selected={fontWeight === weight}
                                                  onClick={() => {
                                                    setFontWeight(weight);
                                                    setAnchorEl(null);
                                                  }}
                                                  sx={{ fontWeight: weight }}
                                                >
                                                  <ListItemDecorator>
                                                    {fontWeight === weight && <Check fontSize="sm" />}
                                                  </ListItemDecorator>
                                                  {weight === '200' ? 'lighter' : weight}
                                                </MenuItem>
                                              ))}
                                            </Menu>
                                            <IconButton
                                              variant={italic ? 'soft' : 'plain'}
                                              color={italic ? 'primary' : 'neutral'}
                                              aria-pressed={italic}
                                              onClick={() => setItalic((bool) => !bool)}
                                            >
                                              <FormatItalic />
                                            </IconButton>
                                          </Box>
                                        }
                                        sx={{
                                          minWidth: 300,
                                          fontWeight,
                                          fontStyle: italic ? 'italic' : 'initial',
                                        }}
                                      />
                                      <Divider />
                                      <Stack
                                        justifyContent="space-between"
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                      >
                                        <Box>
                                      <Typography sx={{marginTop: 2, color: "#f6f7f9"}} variant='h5'>Prioridad {task.priority}</Typography>

                                      <RadioGroup
                                        row
                                        aria-labelledby="prioridad-label"
                                        name="row-radio-buttons-group"
                                        value={cambiarPrioridad ? prioridad : task.priority}
                                        // disabled={deshabilitarCampos}
                                        onChange={(e) => {handlePrioridad(e.target.value)}}
                                      >

                                        {optionPrioridad.map((prioridades, index) => (
                                          <FormControlLabel
                                            key={index}
                                            value={prioridades.value}
                                            control={<Radio />}
                                            label={prioridades.label}
                                          />
                                        ))}
                                      </RadioGroup>
                                        </Box>
                                        <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>

                                          <Button
                                            disabled={deshabilitarCampos}
                                            onClick={() => asignarTarea(task.id, comentario, prioridad, selectedUserId)}
                                          >ASIGNAR TAREA</Button>
                                        </Box>

                                      </Stack>

                                    </FormControl>
                                  }
                                  </Box>
                                    }

                                  {/* <Usuarios idTareaUpdate={selectedTaskId} nombreTarea={modalTitle.toString()} userSelected={userIdAssigned}/> */}
                                </Collapse>
                                </TableCell>
                              </TableRow>
                              </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 100]}
                        component="div"
                        count={filteredTasks.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
          </Container>
        </>
      )}
    </>

  );
};

export default TaskList;