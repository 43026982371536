import React, { useState, useEffect } from 'react';
import CustomAvatar from './CustomAvatar';
import axios from 'axios';
import { useGlobalContext } from './GlobalContextApi';
import { useAuth } from '../AuthContext';
import { Box, InputAdornment, TextField, Stack, Divider, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const UserAvatarList = ({ idDepartamento, avatarSize, onAvatarSelected, usuarioSeleccionado, pid, type, cantUsuarios, maxAncho, refresh, actualizarListaUsuarios }) => {
  const [users, setUsers] = useState([]);
  const [userAssigned, setUserAssigned] = useState({ id: 1 });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [userTasks, setUserTasks] = useState({}); // Estado para almacenar la cantidad de tareas de cada usuario
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { user } = useAuth();
  const { userList, totalesTareas } = useGlobalContext();
  
  // console.log("USER.ID_DEPARTAMENTO", user.id_departamento)

  const handleUserList = async () => {
    try {
      const listaUsuarios = await userList(user.id_departamento);
      console.log("LISTA USUARIO: " , listaUsuarios);

      setUsers(listaUsuarios.data.data);
      // refresh = false;
    } catch (error) {
      console.error('Error al cargar usuarios:', error);
    }
  };

  useEffect(() => {
    handleUserList();
    
  }, [baseUrl]);

  const clearSearch = () => {
    setSearchTerm('');
  };

  // Función para filtrar la lista de usuarios según el valor de búsqueda
  const filteredUsers = users.filter((user) => {
    return user.nombre.toLowerCase().includes(searchTerm.toLowerCase());
  });

 
  useEffect(() => {
    if(refresh === true) {
        handleUserList();
        actualizarListaUsuarios(); // Llama a la función para actualizar la lista de usuarios
        refresh = false; // Establece refresh a false después de actualizar la lista de usuarios
    }
}, [refresh]);

  

  useEffect(() => {
    const fetchData = async () => {
      const userTaskData = {};

      for (const userItem of filteredUsers) {
        try {
          const cantTareas = await cantidadTareasUsuario(userItem.id);
          userTaskData[userItem.id] = cantTareas.data.TotalTareas;
        } catch (error) {
          console.log(error);
          userTaskData[userItem.id] = 0; // Manejar el error o establecer un valor predeterminado
        }
      }

      // Actualiza el estado de la cantidad de tareas de los usuarios una vez
      setUserTasks(userTaskData);
    };

    fetchData();
  }, [filteredUsers]);

  const cantidadTareasUsuario = async (id) => {
    try {
      const cantTareas = await totalesTareas(id);
      return cantTareas;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // Agrupa los usuarios en grupos de 4 para evitar repetición en el carrusel
  const usersCarousel = [];
  for (let i = 0; i < filteredUsers.length; i += cantUsuarios) {
    usersCarousel.push(filteredUsers.slice(i, i + cantUsuarios));
  };



  return (
    <Stack direction="row" spacing={1} sx={{ marginBottom: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ width: 150 }}>
        <TextField
          sx={{ minWidth: 150 }}
          label="Buscar usuario..."
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton onClick={clearSearch}>
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: '16px' }}
        />
      </Box>
      {filteredUsers.length === 0 ? (
        <Typography variant="subtitle2">No se encontraron usuarios</Typography>
      ) : (
        <div style={{ width: '100%', maxWidth: maxAncho }}>
          <Carousel
            showThumbs={false}
            showStatus={false}
            dynamicHeight={false}
            emulateTouch={true}
            showIndicators={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <div onClick={onClickHandler} style={{ position: 'absolute', top: '50%', left: '0', zIndex: 5,  transform: 'translateY(-50%)' }}>
                  <ArrowBackIos sx={{ fontSize: 'medium', cursor: 'pointer', color: '#149eca' }} />
                </div>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <div onClick={onClickHandler} style={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(-50%)' }}>
                  <ArrowForwardIos sx={{ fontSize: 'large', cursor: 'pointer', color: '#149eca' }} />
                </div>
              )
            }
          >

            {usersCarousel.map((userGroup, groupIndex) => (
              <div key={groupIndex} style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 8px' }}>
                {userGroup.map((userItem, subIndex) => (
                  <div key={subIndex} style={{ width: "70px"}}>
                        <CustomAvatar
                        key={userItem.id}
                        tamano={avatarSize}
                        cantidadTareas={userTasks[userItem.id]} // Proporciona la cantidad de tareas desde el estado
                        imagen={baseUrl + userItem.imagen}
                        borderColor="#149eca"
                        userId={userItem.id}
                        disabled={userAssigned.id === userItem.id}
                        userName={userItem.username}
                        bw={true}
                        isSelected={usuarioSeleccionado == userItem.id ? true : false}
                        onClick={() => onAvatarSelected(userItem, pid)} // Pasa el objeto de usuario completo
                        />
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </Stack>
  );
};

export default UserAvatarList;
