import React, { useEffect, useState } from "react";
import axios from "axios";

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/joy/Tooltip';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MovieIcon from '@mui/icons-material/Movie';
import ArticleIcon from '@mui/icons-material/Article';
import Card from '@mui/material/Card';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/joy/Chip';
import Avatar from '@mui/joy/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import FileDownloadButton from "./FileDownloadButton";
import CommentForm from './CommentForm';
import CommentList from "./CommentList";
import AccionesTareas from "./AccionesTareas";
import { css } from '@mui/system';
import Resultado from "./Resultado";
import Skeleton from '@mui/joy/Skeleton';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Button from '@mui/joy/Button';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Container from '@mui/material/Container';
import { useTimer } from "final-countdown-js";
import { useCountDown }  from "final-countdown-js";

import { useAuth } from "./AuthContext";
import StatusChip from "./utils/StatusChip";
import { Stack } from "@mui/material";
import CambiarEstatusTarea from "./utils/CambiarEstatusTarea";

import { useGlobalContext } from "./utils/GlobalContextApi";
import TaskProgress from "./tareas/TaskProgress";
import TiempoFaltante from "./utils/TiempoFaltante";
import { red } from "@mui/material/colors";



const StyledIconButton = styled(IconButton)`
  && {
    background-color: ${({ selected }) => selected ? '#1976d2' : '#999999'};
    margin: 4px;
    color: white;
    font-size: 12px;
    padding: 4px 24px;
    height: 24px;
    border-radius: 12px; /* La mitad del padding */
    transition: background-color 0.3s ease, color 0.3s ease, font-size 0.3s ease;

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }

    span {
      padding: 8px; /* Ajusta el padding del texto */
    }
  }
`;


/*
*
*
*
* AQUI COMIENZA EL COMPONENTE */


const UserTask = ({ taskId, userIdTask, onDescartar }) => {
  // CONTEXT API METHODS 

  // user context
  const { login, user, logout } = useAuth();
  const {obtenerResultado } = useGlobalContext();
  
  const [resultadoTarea, setResultadoTarea] = useState({});

  // funciones globales context
  const {
    tareas,
    completedTasks,
    actualizarEstatus,
    agregarTarea,
    consultarTareas,
    convertirFecha,
  } = useGlobalContext();


  const [tasks, setTasks] = useState([]);

  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filter, setFilter] = useState('all');

  const [comentarios, setComentarios] = useState({});
  const [openRowId, setOpenRowId] = useState(null); // Track the open row
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noUsertask, setNoUserTask] = useState(false);
  // const [completada, setCompletada] = useState(false);
  // const [idTareaCompletada, setIdTareaCompletada] = useState(null);
  const [ultimaFechaAsignacion, setUltimaFechaAsignacion] = useState(null);

  const [open, setOpen] = useState(false);
  
  // ESTA VARIABLE HAY CAMBIARLE EL VALOR SEGUN EL LOGIN
  const [userId, setUserId] = useState(user.id);

  console.log("USSSSERRRR: ", user)

  const [selected, setSelected] = useState(false);
  // resultado
  const [resultado, setResultado] = useState(false);
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [idTareaModal, setIdTareaModal] = useState()
  // const [hovered, setHovered] = useState(false);
  const [hoveredRows, setHoveredRows] = useState({});

  const [comments, setComments] = useState({});
  const [commentAdded, setCommentAdded] = useState(false);
 
  const [mensajeStatus, setMensajeStatus] = useState("");

   // Función para agregar un nuevo comentario a la lista
   const addCommentToList = (nuevoComentario) => {
    setComentarios([...comentarios, nuevoComentario]);
    
  };

  const handleResultadoChange = (newResultado) => {
    setResultado(newResultado);
  };

  const handleFilesLoadedChange = (newFilesLoaded) => {
    setFilesLoaded(newFilesLoaded);
  }

  //DEFINIMOS EL BASE_URL
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const[noArchivo, setNoArchivo] = useState(false);

  const [totalTareas, setTotalTareas] = useState(null);
  const [totalCompletadas, setTotalCompletadas] = useState(null);
  const [totalEnProceso, setTotalEnProceso] = useState(null);
  const [totalIniciadas, setTotalIniciadas] = useState(null);
  const [totalRecibidas, setTotalRecibidas] = useState(null);


  // CONTEXT
  const  {
    totalesTareas
  } = useGlobalContext();

  useEffect(() => {
    // console.log(user.id);

    // Fetch tasks data when userId changes
    // console.log(baseUrl + `usertask.php?consulta=tasklist&uid=${userId}`)
    
    fetch(baseUrl + `usertask.php?consulta=tasklist&uid=${user != null ? user.id : 0}`)
      .then(response => response.json())
      .then(data => {
        setTasks(data.userData);
        setSuccess(true);
          console.log("USERDATATAREAS: ", data.userData)
          if(data.userData.STATUS == "error") {
            console.log("NO HAY TAREAS EN EL USUARIO");
            setNoUserTask(true);
          } else {
                const datosOrdenados = organizarPorFechaDescendente(data.userData);
                // console.log("datosOrdenados", datosOrdenados)
                
                setUltimaFechaAsignacion(datosOrdenados[0].fechaAsignacion);
      
                if(data.userData.STATUS === "error") {
                setSuccess(false);
              } 
          }
          
        // console.log(data.userData);
        // setArchivos(data.userData.archivos);
      });
  }, [userId]);
  
    // Función para filtrar tareas por estado
    useEffect(() => {
      if (filter === 'all') {
        setFilteredTasks(tasks);
      } else {
        const filtered = tasks.filter(task => task.status === filter);
        setFilteredTasks(filtered);
      }
    }, [tasks, filter]);

  // Handler para cambiar el filtro
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setSelected(!selected);
    console.log(newFilter)
  };


  const descartarArchivos = (taskid) => {
    setFilesLoaded(false);
    setOpen(false);  
    toggleRow(taskid);
      
  }
  // obtener el total de las tareas desde el global 
  const obtenerCantidadesTareas = async () => {
    try {
      const totalTareasGlobal = await totalesTareas(user.id);
      console.log("CANTIDADES AWAIT: ", totalTareasGlobal.data.Completadas);
      
      setTotalCompletadas(totalTareasGlobal.data.Completadas);
      setTotalRecibidas(totalTareasGlobal.data.Recibidas);
      setTotalEnProceso(totalTareasGlobal.data.EnProceso);
      setTotalIniciadas(totalTareasGlobal.data.Iniciadas);
      setTotalTareas(totalTareasGlobal.data.TotalTareas);

    } catch (error) {
      console.error("Error al actualizar el estado:", error);
      // Manejar el error aquí si es necesario
    }
  }


  const actualizarTareas = () => {
    console.log("actualizar tareas");
    fetch(baseUrl + `usertask.php?consulta=tasklist&uid=${user != null ? user.id : 0}`)
      .then(response => response.json())
      .then(data => {
      
        setTasks(data.userData);
      });
  }
  const toggleRow = async (taskId) => {

    if(filesLoaded) {
      // console.log("FILES LOADED ")
      setOpen(true);
      setIdTareaModal(taskId); //tomamos el id de la tarea para pasarlo y continuar descartando los archivos
      return;
    } 
    if (openRowId === taskId) {
      setOpenRowId(null);
    } else {
      setOpenRowId(taskId);
      
      handleResultadoTarea(taskId);

      if (!comments[taskId]) {
        await fetchComments(taskId);
      }
    }
  };

  const fetchComments = async (taskId) => {
    try {
      const response = await axios.get(baseUrl + `consultar_comentarios.php?idtarea=${taskId}`);
      if (response.data.commentsdata && Array.isArray(response.data.commentsdata)) {
        setComments({ ...comments, [taskId]: response.data.commentsdata }); // Cambio aquí
        console.log("COMMENTS.DATA: " , response.data.commentsdata)
        setSuccess(true);
        setLoading(false);
        
      }
    } catch (error) {
      console.error('Error al obtener comentarios:', error);
    }
  };

  const handleResultadoTarea = async(task) => {
    try {
      const resultado = await obtenerResultado(task);
      setResultadoTarea(resultado);
      console.log("RESULTADO: ", resultado);
    } catch (error) {
      
    }
  }


  useEffect(() => {
      if (commentAdded && openRowId !== null) {
        const fetchData = async () => {
          await fetchComments(openRowId);
        };
        fetchData();
      }
  }, [commentAdded, openRowId]); // Elimina fetchComments de las dependencias

  const handleCommentAdded = () => {
    setCommentAdded(!commentAdded); 
  };

  const fullPath = (ruta) => {
    const path = baseUrl + ruta;
    return path;
  }


  useEffect(() => {
    // Obtiene las cantidades de tareas cuando el componente se monta o cuando userId cambia.
    obtenerCantidadesTareas();
  }, [userId]); 


  // manejamos el valor que retorna de onactualizado de cambiar status
  // 1 = EL VALOR ES IGUAL AL QUE TENIA
  // 2 = ACTUALIZO

  const handleOnActualizado = (valor) => {
    if(valor == 2 ){
      actualizarTareas();
      obtenerCantidadesTareas();
    }
    console.log("VALOR ONACTUALIZADO: ", valor);
  };

  
  // Función para organizar el objeto por fecha de manera descendente
  const organizarPorFechaDescendente = (data) => {
    return data.sort((a, b) => {
        const fechaA = new Date(a.fechaAsignacion);
        const fechaB = new Date(b.fechaAsignacion);
        return fechaB - fechaA;
    });
  }

  
  // console.log("Fecha de asignación del último elemento:", ultimaFechaAsignacion);
  return (
    <>
      <Container maxWidth="xl">
    
      {/* MODAL */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            variant="h3"
            // startDecorator={<WarningRoundedIcon />}
            sx={{marginBottom: 2, marginLeft: 2}}
          >
            Alerta
          </Typography>
          <Divider />
          <Typography sx={{margin: 2}}id="alert-dialog-modal-description">
            Tienes archivos cargados en una tarea que no has completado de Subir.
            <Typography variant="body-xs">
              Estas seguro que deseas descartar esos cambios?
            </Typography>
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button 
              variant="plain" 
              color="neutral" 
              onClick={() => setOpen(false)}>
              Cancelar
            </Button>
            <Button 
              variant="solid" 
              color="danger" 
              onClick={() => descartarArchivos(idTareaModal)}>
              Descartar Archivos
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    {success ? ( 

    <>
    <Box sx={{background: "#f2f2f2", padding: 4, borderRadius:2, marginBottom: 2}}>
        <Stack  direction="row" spacing={2} sx={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h2" sx={{fontSize: 32,  marginBottom: 2}}>Progreso de tus tareas</Typography>
          <Stack  direction="row" spacing={1} sx={{display: "flex", justifyContent: 'flex-end'}}>
            <StatusChip status="Recibida"  label="Recibidas"/><Typography variant="h3" sx={{fontSize: 24}}>{totalRecibidas}</Typography>
            <StatusChip status="Iniciada"  label="Iniciadas" /><Typography variant="h3" sx={{fontSize: 24}}>{totalIniciadas}</Typography>
            <StatusChip status="En Proceso" label="En Proceso"/><Typography variant="h3" sx={{fontSize: 24}}>{totalEnProceso}</Typography>
            <StatusChip status="Completada" label="Completadas" /><Typography variant="h3" sx={{fontSize: 24}}>{totalCompletadas  }</Typography>
          </Stack>
        </Stack>
        <Divider sx={{marginBottom: 2}} /> 
        <TaskProgress
          completedTasks={totalCompletadas}
          totalTasks={totalTareas}
          mensaje="La tarea mas reciente asignada fue: "
          projectCreationDate={ultimaFechaAsignacion} //cambiar esta fecha por la mas reciente de una tarea

        />
    </Box>
    {noUsertask == false ? 
    <>
     <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2, borderBottom: 1, borderColor: "#cccccc", paddingBottom: 2 }}>
        <Typography variant="h2" sx={{fontSize: 24}}>Filtrar Tareas</Typography>

        <StyledIconButton onClick={() => handleFilterChange('all')} color={filter === 'all' ? 'primary' : 'default'} selected={filter === 'all' ? selected : false}>
          Todas
        </StyledIconButton>
        <StyledIconButton onClick={() => handleFilterChange('Iniciada')} color={filter === 'Iniciada' ? 'primary' : 'default'} selected={filter === 'Iniciada' ? selected : false}>
          Iniciadas
        </StyledIconButton>
        <StyledIconButton onClick={() => handleFilterChange('Recibida')} color={filter === 'Recibida' ? 'primary' : 'default'} selected={filter === 'Recibida' ? selected : false}>
          Recibidas
        </StyledIconButton>
        <StyledIconButton onClick={() => handleFilterChange('En Proceso')} color={filter === 'En Procreso' ? 'primary' : 'default'} selected={filter === 'En Proceso' ? selected : false}>
          En Proceso
        </StyledIconButton>
        <StyledIconButton onClick={() => handleFilterChange('Completada')} color={filter === 'Completada' ? 'primary' : 'default'} selected={filter === 'Completada' ? selected : false}>
          Completadas
        </StyledIconButton>
      </Box>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">Comentarios</TableCell>
            <TableCell align="left">Solicitud</TableCell>
            <TableCell align="left">Departamento</TableCell>
            <TableCell align="left">Prioridad</TableCell>
            <TableCell align="left">Fecha Asignación</TableCell>
            <TableCell align="left">Fecha Límite</TableCell>
            <TableCell align="left">Estado</TableCell>
            <TableCell align="left">Cambiar Estado  </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTasks.map((task) => (
            <React.Fragment key={task.id}>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={task.id}>
                <TableCell>
                <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => toggleRow(task.id)}
                          >
                            {openRowId === task.id ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                </TableCell>
                <TableCell>
                <Tooltip title="Comentarios No leidos" placement="top">
                  <Chip
                      color="primary"
                      // onClick={function(){}}
                      size="sm"
                      variant="solid"
                    >
                      <Typography 
                        level="body-xs" 
                        variant="plain" 
                        sx={{ color:'white', fontSize: '14px', fontWeight: 400}}>
                          {task.cantidadComentarios}
                      </Typography>
                  </Chip>
                 </Tooltip>   
                </TableCell>

                <TableCell align="left">{task.description}</TableCell>
                <TableCell align="left">{task.departamento}</TableCell>
                <TableCell align="left">{task.priority}</TableCell>
                <TableCell align="left">{convertirFecha(task.fechaAsignacion)}</TableCell>
                <TableCell align="left">
                  {convertirFecha(task.deadline)}
                  {task.fechaAsignacion && 
                    <TiempoFaltante fechaFinal={task.fechaAsignacion} fechaInicio={task.deadline} />
                  }
                </TableCell>
                <TableCell align="left">
                {task.status == "Recibida" ? (
                    <StatusChip status="Recibida" />
                    ) 
                    : task.status === "Iniciada" ? (
                    <StatusChip status="Iniciada" />
                    ) 
                    : task.status == "En Proceso" ? (
                    <StatusChip status="En Proceso" />
                    ) 
                    : task.status === "Completada" ?
                    <StatusChip status="Completada" />
                    : ""
                  }
                </TableCell>
                <TableCell>
                <CambiarEstatusTarea
                    projectId={task.proyecto ? task.proyecto : 0}
                    mensaje={
                      task.status && task.status == "Recibida" ? "INICIAR la Tarea" 
                      : task.status && task.status == "Iniciada" ? "Cambiar a Status: EN PROCESO" 
                      : "Cambiar a Status: COMPLETADA"
                     }
                    onActualizado={handleOnActualizado}
                    taskId={task.id}
                    status={task.status}
                    iconType={task.status == "Iniciada" || task.status == "En Proceso" ?  1 : 2}
                    completada={task.status == "Completada" ? true : false}
                  />
                </TableCell>
              </TableRow>

              <TableRow sx={{background: "blue"}}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#191837' }} colSpan={9}>
                  <Collapse in={openRowId === task.id} timeout="auto">
                  <Typography sx={{color: "#ffffff"}}>{task.archivos}</Typography>
                    
                    {(resultado && task.archivos.data) && (
                      <Box sx={{ backgroundColor: 'gray', width: '100%', padding: 2, borderRadius: 2, mt: 2 }}>
                              <Table size="small" aria-label="comments">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Archivos </TableCell>
                                  <TableCell />
                                  <TableCell>Descargar</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.values(JSON.parse(task.archivos)).map((archivo) => (
                                  <TableRow key={archivo.id}>
                                    <TableCell style={{ width: '5%' }}>
                                      { archivo.name.endsWith('.png') ? <ImageIcon /> :
                                        archivo.name.endsWith('.pdf') ? <PictureAsPdfIcon /> :
                                        archivo.name.endsWith('.mp4') ? <MovieIcon /> :
                                        archivo.name.endsWith('.docx') ? <ArticleIcon /> :
                                        archivo.name.endsWith('.doc') ? <ArticleIcon /> :
                                        <InsertDriveFileIcon />
                                      }
                                    </TableCell>
                                    <TableCell style={{ width: '90%' }} component="th" scope="row">{archivo.name}</TableCell>
                                    <TableCell style={{ width: '5%' }}>
                                      <FileDownloadButton filePath={fullPath(archivo.path)} />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          
                      </Box>
                       )}
                      <Stack>
                      <Typography variant="h4" padding={2} sx={{color: "white"}} component="div">
                         {task.description}
                      </Typography>

                      
                      </Stack>
                    <Box>
                    
                      {task.archivos && typeof task.archivos === 'string' && !JSON.parse(task.archivos).data && openRowId === task.id ? (
                        <Box sx={{ margin: 1 }}>
                          <Paper elevation={3} sx={{ borderRadius: 2, padding: 5 }} >
                            <Table size="small" aria-label="comments">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Archivos {JSON.parse(task.archivos).data} </TableCell>
                                  <TableCell />
                                  <TableCell>Descargar</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                
                                {Object.values(JSON.parse(task.archivos)).map((archivo) => (
                                  <TableRow key={archivo.id}>
                                    <TableCell style={{ width: '5%' }}>
                                    
                                    { archivo.data == "nodata" &&
                                        archivo.name.endsWith('.png') ? <ImageIcon /> :
                                        archivo.name.endsWith('.pdf') ? <PictureAsPdfIcon /> :
                                        archivo.name.endsWith('.mp4') ? <MovieIcon /> :
                                        archivo.name.endsWith('.docx') ? <ArticleIcon /> :
                                        archivo.name.endsWith('.doc') ? <ArticleIcon /> :
                                        <InsertDriveFileIcon />
                                      }
                                    </TableCell>
                                    <TableCell style={{ width: '90%' }} component="th" scope="row">{archivo.name}</TableCell>
                                    <TableCell style={{ width: '5%' }}>
                                      <FileDownloadButton filePath={fullPath(archivo.path)} />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            </Paper>
                        </Box>
                      ) : (
                        <Typography sx={{color: "#ffffff"}} variant="body1" padding={2} gutterBottom component="div">
                            No se adjuntaron archivos para esta tarea.
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ margin: 1 }}>
  

                      <Box display="flex" mb={3} sx={{ marginTop: 2}}>
                        <Box flex="1">
                          <Paper elevation={3} sx={{ borderRadius: 2 }} >
                            <CommentList commentObject={comments[task.id] || []} taskId={task.id} />
                          </Paper>
                        </Box>
                        <Box flex="1" ml={2} >
                            <CommentForm 
                              onSuccess={handleCommentAdded} 
                              taskId={task.id} 
                              userIdTask={userId} 
                              projectId={1}
                            />
                            {/* RENDERIZAR RESULTADO AQUI */}

                            <Resultado 
                              idTarea={task.id} 
                              onResultado={handleResultadoChange} 
                              onFilesLoaded={handleFilesLoadedChange} 
                              idDepartamento={task.id_departamento} 
                              idUsuario={userId}/>
                           
                           <Box display="flex" mb={3} sx={{ marginTop: 2}}>
                        <Box flex="1">
                          
                          {resultadoTarea.status === "OK" ?
                                <Paper elevation={3} sx={{ borderRadius: 2 }} >
                                  <Typography variant="h2" sx={{fontSize: 30, padding:2}}>Resultados</Typography>
                                <Table size="small" aria-label="comments">
                                    <TableHead>
                                      <TableRow key={resultadoTarea.data.id} >
                                        <TableCell>Archivos </TableCell>
                                        <TableCell />
                                        <TableCell>Descargar</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
      
                                  {Object.values(JSON.parse(resultadoTarea.data.resultado)).map((result, index) => (
                                      <TableRow key={index}>
                                        <TableCell style={{ width: '5%' }}>
                                          { result.name.endsWith('.png') ? <ImageIcon /> :
                                            result.name.endsWith('.pdf') ? <PictureAsPdfIcon /> :
                                            result.name.endsWith('.mp4') ? <MovieIcon /> :
                                            result.name.endsWith('.docx') ? <ArticleIcon /> :
                                            result.name.endsWith('.doc') ? <ArticleIcon /> :
                                            <InsertDriveFileIcon />
                                          }
                                        </TableCell>
                                        <TableCell style={{ width: '90%' }} component="th" scope="row">{result.name}</TableCell>
                                        <TableCell style={{ width: '5%' }}>
                                          <FileDownloadButton filePath={fullPath(result.path)} />
                                        </TableCell>
                                      </TableRow>
                                  ))}
                                  </TableBody>
                                  </Table>
                                </Paper> 
                              
                            : 
                            <Paper elevation={3} sx={{ borderRadius: 2, padding:2 }} >
                              <Typography variant="h6">DEBE CARGAR EL RESULTADO DE LA TAREA UNA VEZ HAYA TERMINADO</Typography>
                            </Paper>
                          }
                       
                        </Box>
                    </Box>

                        </Box>
                      </Box>
                    </Box>

                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
     : <Box>
        <Typography variant="h3">No tienes tareas asignadas</Typography>
      </Box>
     }
    </>
    ): (
      <>
        <Typography variant="h2">Aún no tienes tareas asignadas</Typography>
      </>
    ) }
    </Container>
    </>
  );
}


const chipStyles = {
  transition: 'background-color 0.3s ease, color 0.3s ease',
};

const chipHoverStyles = {
  backgroundColor: 'var(--palette-success-main)',
  color: 'white',
};


export default UserTask;
