import React, { useEffect, useState } from "react";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import { keyframes } from "@mui/system";
import '../styles/Radio.css';

const Resultado = ({idTarea, idUsuario, idDepartamento, onResultado, onFilesLoaded}) => {
    const [uploadStatus, setUploadStatus] = useState("");
    const [cleanFiles, setCleanFiles] = useState(null);
    const [error, setError] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [filesList, setFilesList] = useState([]);
    const fileTypes = ["JPEG", "JPG", "PNG", "GIF", "DOC", "DOCX", "PDF", "DOC", "DOCX", "XLS", "XLSX", "PSD", "AI", "PPTX", "SVG"];
    // const [subirArchivoMsg, setSubirArchivoMsg] = useState("No se han subido archivos")
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [selectedFile, setSelectedFile] = useState();

    const handleInputChange = (e) => {
        onFilesLoaded(true);
        setSelectedFile(e);
        console.log(e.length);

        const files = {};

        for (let i = 0; i < e.length; i++) {
            files[i] = {
                name : e[i].name
            }
            
          }

        setFilesList(JSON.stringify(files));
        console.log("FILES OBJECT ", files);
    };

    // console.log("FILE LIST: ", fileList[0]);

    
     const handleUpload = async () => {

        const data = new FormData();
    
        for (let i = 0; i < selectedFile.length; i++) {
          data.append("file[]", selectedFile[i]);
          
        }
        data.append("idDepartamento", idDepartamento);
        data.append("idUsuario", idUsuario);
        data.append("idTarea", idTarea);
    
        let url = baseUrl + 'upload_resultado.php';
        try {
            const response = await axios.post(url, data, 
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            // Crear el temporizador
            // const tiempoEspera = 5000; // 3000 milisegundos = 3 segundos
            
            setUploadStatus("Archivos subidos correctamente.");
            onResultado(true);
          
            setError(false) //mantenemos el error en falso por si acaso da un error y se vuelve a enviar
            setFilesList([]); //limpiamos el array de archivos para que se envie dos veces
            setCleanFiles(null); //limpiamos el campo para reiniciarlo

            const tiempoEspera = 5000; // 5000 milisegundos = 5 segundos
            setShowMsg(true);
    
            setTimeout(() => {
                setShowMsg(false);
            }, tiempoEspera);


            // // Cerrar el mensaje después del tiempo de espera
            // setTimeout(() => {
            //     setShowMsg(false);
            // }, tiempoEspera);
            

            // // Activar la animación fadeInOut durante el tiempo de espera
            // const fadeInOutDuration = 3000; // Duración de la animación en milisegundos
            // setTimeout(() => {
            //     fadeInOutAnimation();
            // }, tiempoEspera - fadeInOutDuration); // Ejecutar la animación justo antes de que termine el temporizador

            console.log(response.data); 

        } catch (error) {
            setUploadStatus("Error al subir archivos.");
            setError(true);
            console.error(error);
        }
    
    }
    
    const handleCleanFiles = () => {
        setCleanFiles([]);
        setFilesList([])
    }

    const cerrarMensaje = () => {
        setShowMsg(false);
    }
   


    return (
        <Paper elevation={3} sx={{ marginTop: 2, borderRadius: 2 }} >
            <Typography variant="h6" padding={2}  component="div">
                Resultado
            </Typography>
            <Typography level="body-md" padding={2} gutterBottom component="div">
                Cuando la tarea sea completada debe subir el resultado en este apartado y marcar la tarea como concluida.
            </Typography>   
            
                <Box sx={{backgroundColor: 'white'}} padding={2}>
                <FileUploader
                    multiple={true}
                    handleChange={handleInputChange}
                    name="file"
                    fileOrFiles={cleanFiles}
                    label="Cargue o arrastre los archivos aquí"
                    hoverTitle="Deje caer aquí"
                    types={fileTypes}
                    />
                </Box>
                <Typography variant="body-md" padding={2} component="div">
                    {filesList.length > 0 ? (
                        Object.values(JSON.parse(filesList)).map((file, index) => (
                            <div key={index} >{file.name}</div>
                        ))
                       
                    ) : (
                        "No se han subido archivos"
                    )}

                   
                      
                </Typography>  
                <Box>
                    {showMsg &&
                        <Chip
                            id="uploadStatusChip"
                            color={error ? "danger" : "success" }   
                            size="xs"
                            variant="solid"
                            className="fade-out"

                            sx={{ 
                                color: "white", 
                                marginTop: 2, 
                                marginLeft: 2,
                                // animation: `${fadeInOut} 5s linear forwards`, // Se aplica el efecto de desvanecimiento
                            }}
                            >
                            <Typography sx={{color: "white", paddingLeft: 2, paddingRight: 2}}> {uploadStatus}</Typography>
                        </Chip>  
                    }        

                </Box>
                <Box>

                                                           
                        
                <Button 
                    disabled={filesList.length > 0 ? false : true } 
                    onClick={handleUpload} 
                    sx={{margin: 2}} >Subir Archivos</Button>
                <Button 
                    color="neutral"
                    variant="outlined"
                    disabled={filesList.length > 0 ? false : true } 
                    onClick={handleCleanFiles} 
                    sx={{margin: 2}} >Remover Archivos</Button>
                </Box>

        
        </Paper>

    );
};

export default Resultado;
