import React from 'react';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { formatDistanceToNow, isAfter } from 'date-fns';
import es from 'date-fns/locale/es';
import StatusChip from '../utils/StatusChip';

const TaskProgress = ({ completedTasks, totalTasks, projectCreationDate, projectDeadline, mensaje }) => {
  const progress = (completedTasks / totalTasks) * 100;
  const elapsedDays = formatDistanceToNow(new Date(projectCreationDate), {
    addSuffix: false,
    locale: es,
  });


  // Calcula si el proyecto está atrasado
  const isProjectDelayed = isAfter(new Date(), new Date(projectDeadline));

  let color = '#ff0000'; // Rojo por defecto
  if (progress >= 25 && progress < 50) {
    color = '#ffa500'; // Naranja
  } else if (progress >= 50 && progress < 75) {
    color = '#decb00'; // Amarillo
  } else if (progress >= 75) {
    color = '#008000'; // Verde
  }

  let statusProyecto = progress < 100 ? 'En Proceso' : 'Completado';

  return (
    <div>
      <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h3" sx={{ fontSize: 30, color: color }}>
          {progress > 0 ? Math.round(progress): 0}%
        </Typography>
        <StatusChip status={statusProyecto} />
      </Stack>
      <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography variant="subtitle2" sx={{ color: '#888888' }}>
          Tareas completadas:
        </Typography>
        <Typography variant="subtitle2" sx={{ color: '#666666' }}>
          {completedTasks} / {totalTasks}
        </Typography>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{ backgroundColor: color }}
        sx={{ backgroundColor: color, marginTop: 1, marginBottom: 1 }}
        color="primary"
      />
      {mensaje ? (
        <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: '#888888' }}>{mensaje}</Typography>
          <Typography variant="subtitle2" sx={{ color: '#666666' }}>{elapsedDays}</Typography>
        </Stack>
      ) : (
        <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: '#888888' }}>Creado hace:</Typography>
          <Typography variant="subtitle2" sx={{ color: '#666666' }}>{elapsedDays}</Typography>
        </Stack>
      )}
      {isProjectDelayed && (
        <Typography variant="subtitle2" sx={{ color: 'red' }}>
          ¡Proyecto atrasado!
        </Typography>
      )}
    </div>
  );
};

export default TaskProgress;
