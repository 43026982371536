import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { format, differenceInMilliseconds } from 'date-fns';

import styled from 'styled-components';
import Chip from '@mui/material/Chip';

// Función para obtener el color de acuerdo al estado
const getStatusColor = (status) => {
  switch (status) {
    case 'green':
      return '#4CAF50'; // Verde
    case 'orange':
      return '#FFC107'; // Naranja
    case 'red':
      return '#F44336'; // Rojo
    default:
      return 'transparent';
  }
};

// Estilo personalizado para el Chip
const StyledChip = styled(Chip)(({ status }) => ({
  backgroundColor: getStatusColor(status),
  color: '#fff',
  '& .MuiChip-icon': {
    color: '#fff',
    fontSize: '16px', // Cambia el tamaño del icono según tus preferencias
  },
}));

const TiempoFaltante = ({ fechaFinal, fechaInicio }) => {
  const [diasFaltantes, setDiasFaltantes] = useState(0);
  const [horasFaltantes, setHorasFaltantes] = useState(0);
  const [progressColor, setProgressColor] = useState('green');
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const fechaFinalMs = new Date(fechaFinal).getTime();
    const fechaInicioMs = new Date(fechaInicio).getTime();
    const nowMs = Date.now();

    if (nowMs < fechaInicioMs) {
      // Si la fecha actual es antes de la fecha de inicio
      setDiasFaltantes(Math.floor((fechaInicioMs - nowMs) / (1000 * 60 * 60 * 24)));
      setHorasFaltantes(Math.floor((fechaInicioMs - nowMs) / (1000 * 60 * 60)) % 24);
      setProgressColor('green');
    } else if (nowMs < fechaFinalMs) {
      // Si la fecha actual está entre la fecha de inicio y la fecha final
      const tiempoTranscurrido = nowMs - fechaInicioMs;
      const tiempoTotal = fechaFinalMs - fechaInicioMs;
      const porcentaje = (tiempoTranscurrido / tiempoTotal) * 100;

      if (porcentaje <= 25) {
        setProgressColor('green');
      } else if (porcentaje <= 50) {
        setProgressColor('orange');
      } else if (porcentaje <= 75) {
        setProgressColor('orange'); // Cambié el color a naranja en lugar de rojo aquí
      } else {
        setProgressColor('red');
      }

      setDiasFaltantes(Math.floor((fechaFinalMs - nowMs) / (1000 * 60 * 60 * 24)));
      setHorasFaltantes(Math.floor((fechaFinalMs - nowMs) / (1000 * 60 * 60)) % 24);
    } else {
      // Si la fecha actual es después de la fecha final
      setDiasFaltantes(0);
      setHorasFaltantes(0);
      setProgressColor('red');
      setExpired(true); // El proyecto ha pasado de tiempo
    }
  }, [fechaFinal, fechaInicio]);

  // Calcular el tiempo transcurrido después de la fecha límite
  const fechaFinalMs = new Date(fechaFinal).getTime();
  const nowMs = Date.now();
  const tiempoExpiradoMs = nowMs - fechaFinalMs;

  const diasExpirados = Math.floor(tiempoExpiradoMs / (1000 * 60 * 60 * 24));
  const horasExpiradas = Math.floor(tiempoExpiradoMs / (1000 * 60 * 60)) % 24;

  return (
    <Box>
      <Box>
        {expired ? (
          <StyledChip
            size="small"
            status="red"
            icon={<AccessTimeIcon fontSize="small" />}
            label={
              <Typography variant="caption" color="white">
                Expiró hace {diasExpirados} días y {horasExpiradas} horas
              </Typography>
            }
          />
        ) : (
          <StyledChip
            status={progressColor}
            size="small"
            variant="outline"
            label={
              <Typography variant="caption" color="white">
                {`Faltan ${diasFaltantes} días ${horasFaltantes} horas`}
              </Typography>
            }
            icon={<AccessTimeIcon fontSize="small" />}
          />
        )}
      </Box>
    </Box>
  );
};

export default TiempoFaltante;
