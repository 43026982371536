import React from 'react';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const FileDownloadButton = ({ filePath }) => {
  const downloadFile = () => {
    fetch(filePath)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filePath.substring(filePath.lastIndexOf('/') + 1); // Obtiene el nombre del archivo
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error al descargar el archivo:', error);
      });
  };

  return (
    <IconButton onClick={downloadFile}>
      <DownloadForOfflineIcon />
    </IconButton>
  );
};

export default FileDownloadButton;
