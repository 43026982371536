import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/joy/Tooltip';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import TaskList from "./TaskList";
import Tareas from "./Tareas";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Stack } from "@mui/material";
import CustomModal from "./utils/CustomModal";
import CreateProject from "./proyectos/CreateProject";
import TaskProgress from "./tareas/TaskProgress";
import AddTaskIcon from '@mui/icons-material/AddTask';
import TaskForm from "./TaskForm";
import UserAvatarList from "./utils/UserAvatarList";

import { useAuth } from "./AuthContext";
import { useGlobalContext } from "./utils/GlobalContextApi";
import TiempoFaltante from "./utils/TiempoFaltante";
/*
*
*
*
* AQUI COMIENZA EL COMPONENTE */

const Projects = () => {
  const { login, user, logout } = useAuth();
  const [openRowId, setOpenRowId] = useState(null);
  const [open, setOpen] = React.useState(false);

  const [userRole, setUserRole] = useState(user.rol_id);

    // guardamos la lista de proyectos
  const [projectList, setProjectList] = useState([]);

  // definimos el baseurl
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { convertirFecha } = useGlobalContext(); 
  // PAGINATION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  // inicializamos la lista de proyectos
  useEffect(() => {
    handleProjectList();

  }, []);

  const handleProjectList = async (uid) => {
    try {
      const response = await axios.get(baseUrl + `proyectos.php?uid=${uid}`);
      console.log(response.data.proyectos);
      // console.log( "PROJECT_LIST: ", response.data.userData.proyectos)
      // const cantProyectos = response.data.proyectos ? response.data.proyectos : response.data.userData.proyectos;
      setProjectList(response.data.proyectos);
      
      // console.log("CANT-PROYECTOS", cantProyectos);
    } catch (error) {
      console.log("ERROR LEYENDO LOS PROYECTOS: ", error);
    }
  };

  const [pId, setPid] = useState(null);

  const toggleRow = async (id) => {
    // userTask(taskId);
    // console.log( userIdAssigned)
    if (openRowId === id) {
      setOpenRowId(null);

      // handleUsuarios();
    } else {
      setOpenRowId(id);
    }
  };

  const handleAgregarProyecto= () => {
    console.log("AGREGAR PROYECTO");
    setAgregarProyecto(true);
    setOpenModal(true);
  }
 
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    console.log("AGREGAR PROYECTO NUEVO")
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAgregarTarea(false);
    setAgregarProyecto(false);

  };


  const refreshProjects = (success) => {
    if(success){
      
      handleProjectList();
      console.log("ONSUCCESS: ", success);
   }
  }

  const [agregarTarea, setAgregarTarea] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [agregarProyeto, setAgregarProyecto] = useState(false);

  const handleAgregarTarea = (pid) => {
    setOpenModal(true);
    setAgregarProyecto(false);

    setAgregarTarea(true);
    setSelectedProjectId(pid);
    console.log("PID", pid)
    // setOpenModal(true);
    // setIdTareaEliminar(false);

  }


  return (
    <Container maxWidth="xl">

      <CustomModal open={openModal} onClose={handleCloseModal}>
        {agregarTarea ? 
          <TaskForm proyecto={selectedProjectId} /> 
        : agregarProyeto &&
        <CreateProject userId={user.id} onCloseModal={handleCloseModal} onSuccess={refreshProjects}/>
        
      }
      
      
      </CustomModal>
      {projectList != null ? 
      <Box>
      <Stack direction="row" spacing={2}>
        {/* <UserAvatarList idDepartamento={user.id_departamento} /> */}
        
        <Typography variant="h2" sx={{marginBottom: 5}}>Proyectos</Typography>
          {userRole === 1 &&<Tooltip title="Agregar Proyecto"  placement="right">
            <IconButton
              onClick={handleAgregarProyecto}
            >
              <AddCircleIcon sx={{color: "#1976d2", fontSize: 50}} />
            </IconButton>
          </Tooltip>}
          </Stack>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
          
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell >id</TableCell>  
                <TableCell>Proyecto {projectList.length }</TableCell>
                <TableCell>Fecha Agreado</TableCell>
                <TableCell>Fecha Límite</TableCell>
                <TableCell>Tareas</TableCell>
                <TableCell>Progreso de Proyecto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {projectList.map((proyecto) => (
              <React.Fragment key={proyecto.id}>

                <TableRow
                  key={proyecto.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => toggleRow(proyecto.id)}
                    >
                      {openRowId === proyecto.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{proyecto.id}</TableCell>
                  <TableCell>{proyecto.nombre_proyecto}</TableCell>
                  
                  <TableCell>{convertirFecha(proyecto.fecha_creacion)}</TableCell>
                  <TableCell>
                  {convertirFecha(proyecto.fecha_creacion)}
                    <TiempoFaltante fechaFinal={proyecto.fecha_creacion} fechaInicio={proyecto.fecha_limite} /></TableCell>
                  <TableCell>{proyecto.cantidad_tareas}</TableCell>
                  <TableCell>
                    {/* Componente de progreso */}
                    { user.rol_id == "1" && proyecto.cantidad_tareas == 0 || proyecto.cantidad_tareas == null || isNaN(proyecto.cantidad_tareas) ? 
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleAgregarTarea(proyecto.id)}>
                        Agregar Tarea 
                      </Button>
                    : user.rol_id != "1" && proyecto.cantidad_tareas == 0  ? 
                      <Typography>Este proyecto aún no tiene tareas </Typography>
                    : proyecto.cantidad_tareas > 0  ? 
                    <TaskProgress
                      completedTasks={proyecto.tareas_completadas}
                      totalTasks={proyecto.cantidad_tareas}
                      projectCreationDate={proyecto.fecha_creacion}
                    />: 
                    <></> 
                  }
                  </TableCell>
                </TableRow>
                <TableRow sx={{ background: "blue" }}>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#191837' }} colSpan={12}>
                    <Collapse in={openRowId === proyecto.id} timeout="auto" >

                      <Box sx={{marginTop: 5, minHeight: 300}}>
                      {openRowId === proyecto.id && ( // si no se clickea una fila, no carga los proyectos
                          <TaskList key={proyecto.id} prid={proyecto.id} />
                          )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}

            </TableBody>
          </Table>
        </TableContainer>
      {/* <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={projectList.length != 0 ? projectList.length : 0 }
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    /> */}
  </Box> : 
  <Box>
    <Typography variant="h2">No hay proyectos registrados</Typography> 
    {userRole === 1 &&<Tooltip title="Agregar Proyecto"  placement="right">
            <IconButton
              onClick={handleAgregarProyecto}
            >
              <AddCircleIcon sx={{color: "#1976d2", fontSize: 50}} />
            </IconButton>
          </Tooltip>}
    </Box>}
 </Container>
  )

}

export default Projects;
