import React from 'react';
import { Container, Typography, Avatar, Paper, Button } from '@mui/material';
// import {makeStyles} from '@mui/styles/makeStyles';
import { useAuth } from '../AuthContext';


const UserProfile = () => {
//   const classes = useStyles();
  const { user, logout } = useAuth();

  return (
    <Container maxWidth="md" >
      <Avatar alt="User Avatar" src={user?.imagen}/>
      <Typography variant="h5" component="h1" gutterBottom>
        Perfil de Usuario
      </Typography>
      <Paper elevation={3}>
        <Typography variant="subtitle1" gutterBottom>
          Nombre: {user?.nombre}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Correo Electrónico: {user?.correo}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Código de Empleado: {user?.codigoEmpleado}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Departamento: {user?.idDepartamento}
        </Typography>
      </Paper>
      <Button
        variant="outlined"
        color="primary"
        onClick={logout}
      >
        Cerrar Sesión
      </Button>
    </Container>
  );
};

export default UserProfile;
