import React, { useState, useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AccessTime from '@mui/icons-material/AccessTime';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import ComputerIcon from '@mui/icons-material/Computer';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';

import { Container, Form, Row, Col } from 'react-bootstrap';
import SelectOther from './SelectOther';
import { Input, TextField, TextareaAutosize } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import UserImage from "./UserImage";
import Tooltip from '@mui/material/Tooltip';
import SeguimientoTarea from './utils/SeguimientoTarea';


const Consulta = () => {
    const [tokenDeConsulta, setTokenDeConsulta] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [fechaRecibida, setFechaRecibida] = useState("");
    const [errorMsgConsulta, setErrorMsgConsulta] = useState("");
    const [users, setUsers] = useState([]); 
    const [ticketNumber, setTicketNumber] = useState("");
    const [imagenUsuario, setImagenUsuario] = useState("");
    const [nombreUsuario, setNombreUsuario] = useState("");
    const [userName, setUsername] = useState("");
    const [cantidadTareas, setCantidadTareas] = useState("");
    const [idUsuario, setIdUsuario] = useState("");

    const [statusSolicitud, setStatusSolicitud] = useState("");
    const [error, setError] = useState(false);

    const [titleConsulta, setTitleConsulta] = useState("");

    const [comentarios, setComentarios] = useState([]);

   //DEFINIMOS EL BASE_URL
   const baseUrl = process.env.REACT_APP_BASE_URL;


   // AQUI CONSULTAMOS LA TAREA
    const consultarTarea = async (ticketNum) => {
      // Mostrar el loader

      // console.log("TICKET NUM: ", ticketNum)
      setTicketNumber(ticketNum);

      setLoading(true);

      if(ticketNumber == "") {
        setErrorMsgConsulta("El campo está vacío, introduzca el Token que recibió en el correo de su solicitud, ej: CCCBS-2023-00002")
        setLoading(false);
        // return;
      }
      try {

        const url = baseUrl+ "consultar_tarea.php";
        const response = await axios.post(url, {
              // ticket: "CCCBS-2023-000025",
              ticket: ticketNum,
            }, {
              headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'multipart/form-data'
              }, 
              mode: 'no-cors',
            }
          )
          console.log("DATA: ", response);

          let responseData = response.data.response;
            setLoading(false);

          if(response.data.STATUS == "OK") {
      
            setError(false);

            setSuccess(true);
            // setTareaGuardada(true);
            setFechaRecibida(responseData.fechaRecepcion)

            if(responseData.assignedTo) {
              let urlUsuario = baseUrl+"consultar_usuario.php?idusuario="+responseData.assignedTo;
              
              getUser(urlUsuario);
              
              let urlComentarios = baseUrl+"consultar_comentarios.php?idtarea="+responseData.id;
              
              getComments(urlComentarios);
              
              setTitleConsulta("Estatus de la consulta para el ticket: ");
            } 
          } else {
            setTitleConsulta("No se encontraron registros del Ticket: ");
            setErrorMsgConsulta("No se encontró el número de ticket que está consultando, verifique e intente nuevamente.")

            setError(true);
          }

      } catch {
        setLoading(false);
      }
    };

    async function getUser(url) {
      try {
        const response = await axios.get(url);
        console.log("USUARIO: ",  response.data.userdata);
        const userData = response.data.userdata
        
        setUsername(userData.userName);
        setImagenUsuario(userData.imagen);
        setNombreUsuario(userData.nombre);
        setCantidadTareas(userData.total_tareas);
        setStatusSolicitud(userData.status);

      } catch (error) {
        console.error(error);
      }
    }
    async function getComments(url) {
      try {
        const response = await axios.get(url);
        console.log("COMENTARIOS: ",  response.data.commentsdata);
        const commentsData = response.data.commentsdata

        setComentarios(commentsData);
        
        // return true;
      } catch (error) {
        console.error(error);
      }
    }

    
    return (
      <>
          <div>
            <Container>
                <h1>Seguimiento</h1>
                  <form>
                    <div>
                      <TextField 
                         error={ error === true}
                         required
                         label="Consulta el estatus de la solicitud"
                         helperText={error ? errorMsgConsulta : ""}
                         type="text" 
                         onChange={(e) =>  setTokenDeConsulta(e.target.value) } 
                         id="token" 
                         placeholder="DGC-2022-00001" fullWidth sx={{ m: 1 }} />
                        <Button type="button" variant="contained" onClick={() =>consultarTarea(tokenDeConsulta)}>
                          Consultar
                        </Button>
                      {/* <Button onClick={() => consultarTarea(tokenDeConsulta)}>Consultar</Button> */}
                    </div>
                  </form>
                  <h3>Ingrese el número de seguimiento</h3>
                  <p>El número de seguimiento es un numero que contiene las iniciales del departamento que solicita, el año y un numero generado de la solicitud.</p>
                  { error ? (
                    <>
               <h3>{titleConsulta} <strong style={{color: 'red'}}>{ticketNumber}</strong><ErrorIcon style={{ color: 'red' }} /></h3>
                 
                 </>
                  ) :
                    (<h1></h1>)
                  }
                  <h2></h2>
              </Container>
            {loading ? (
              // Mostrar el loader mientras se envía la información
              <CircularProgress />
            ) : success && error == false ? (
              // Mostrar el icono de éxito cuando se ha enviado la información con éxito
              <Container>
                <div className="timeline-status">
                <h5>{titleConsulta} <strong>{ticketNumber} </strong> <CheckCircleIcon style={{ color: 'green' }} /></h5>
                  <Timeline position="alternate">
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                      >
                        {fechaRecibida}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                          <AccessTime />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                          Recibido
                        </Typography>
                        <Typography>Su solicitud fue recibida correctamente</Typography>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                        {fechaRecibida}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                        <UserImage urlImage={imagenUsuario} 
                                            idUsuario={idUsuario}
                                            userName={userName} 
                                            key={idUsuario} 
                                            cantidadTareas={cantidadTareas}
                                            imageSize="large"
                                            disabledImage={ true}
                                        />  
                        </TimelineDot>

                        
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                          Asignado
                        </Typography>
                        <Typography>Su Solicitud fue asignada a <strong>{nombreUsuario}</strong></Typography>
                      </TimelineContent>
                    </TimelineItem>
                    {comentarios.STATUS == "error" ? ("") : (
                    <>
                    {comentarios.map((comentario) => (
                           <TimelineItem key={comentario.id}>
                              <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary"> 
                                <div sx={{ m: 'auto 0' }}>{comentario.hora}</div>
                              </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot color="primary" variant="outlined">
                              <Tooltip title="Add" placement="top-start">
                                <SpeakerNotesIcon />
                                </Tooltip>
                              </TimelineDot>
                              <TimelineConnector sx={{ bgcolor: 'secondary.secondary' }} />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <Typography variant="h6"  component="span">
                                Comentario
                              </Typography>
                              <Typography>{comentario.comentario}</Typography>
                            </TimelineContent>
                         </TimelineItem>)
                         )}
                         </>
                    )}

                    {statusSolicitud == "completado" ? 
                    (<TimelineItem>
                      <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'primary  ' }} />
                        <TimelineDot color="primary">
                          <RepeatIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                          Entregado
                        </Typography>
                        <Typography>Su solicitud concluyó con éxito, la respuesta fue enviada a: <a href='mailto:correo@ejemplo.com'>correo@ejemplo.com</a></Typography>
                      </TimelineContent>
                    </TimelineItem> ) : (
                      <TimelineItem>
                      <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'secondary  ' }} />
                        <TimelineDot color="secondary">
                          <ComputerIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                          En Proceso
                        </Typography>
                        <h6>Su Solicitud se encuentra en proceso, si necesita información adicional puede llamar a la extensión 2408</h6>
                      </TimelineContent>
                    </TimelineItem>
                    )
                    }
                  </Timeline>
                </div>

            </Container>

            ) : (
              // colocar el formulario
      
            <></>

        )}
        </div>

        <SeguimientoTarea token="OSCM2000000000-2023-000030" />
    </>
  )
}

export default Consulta;





// export default App;
