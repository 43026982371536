import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Check from '@mui/icons-material/Check';
import { useAuth } from './AuthContext';
import { useGlobalContext } from './utils/GlobalContextApi';

const CommentForm = ({ onSuccess, taskId, userIdTask , sendButton, projectId}) => {
  const [comment, setComment] = useState('');
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [italic, setItalic] = useState(false);
  const [fontWeight, setFontWeight] = useState('normal');
  const [anchorEl, setAnchorEl] = useState(null);

  const { user } = useAuth();
  const { guardarActividadTarea } = useGlobalContext();

  const handleGuardarActividadTarea = async () => {
    try {
      const actividadTarea = guardarActividadTarea(3, userIdTask, taskId, projectId);
      // if(actividadTarea) {
      //   console.log("ACTIVIDAD TAREA: " , actividadTarea);
      // }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCommentSubmit = async () => {
    try {
      const response = await axios.post(baseUrl + 'guardar_comentario.php', {
        idTarea: taskId,
        comentario: comment,
        user: parseInt(userIdTask),
      });

      if (response.data.status === 'OK') {
        console.log('Comentario guardado correctamente');
        setComment('');

        handleGuardarActividadTarea();
        
        onSuccess(); // Marcar el éxito en el componente padre para actualizar los comentarios
      } else {
        console.error('RESPONSE ERROR', response.data);
      }
    } catch (error) {
      console.error('SEND RESPONSE ERROR', error);
    }
  };

  return (
    <FormControl>
      <Textarea
        minRows={3}
        value={comment}
        placeholder='Escribe tu comentario'
        onChange={(e) => setComment(e.target.value)}
        label="Escribe tu comentario..."
        endDecorator={
          <Box
            sx={{
              display: 'flex',
              gap: 'var(--Textarea-paddingBlock)',
              pt: 'var(--Textarea-paddingBlock)',
              borderTop: '1px solid',
              borderColor: 'divider',
              flex: 'auto',
            }}
          >
            <IconButton
              variant="plain"
              color="neutral"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <FormatBold />
              <KeyboardArrowDown fontSize="md" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              size="sm"
              placement="bottom-start"
              sx={{ '--ListItemDecorator-size': '24px' }}
            >
              {['200', 'normal', 'bold'].map((weight) => (
                <MenuItem
                  key={weight}
                  selected={fontWeight === weight}
                  onClick={() => {
                    setFontWeight(weight);
                    setAnchorEl(null);
                  }}
                  sx={{ fontWeight: weight }}
                >
                  <ListItemDecorator>
                    {fontWeight === weight && <Check fontSize="sm" />}
                  </ListItemDecorator>
                  {weight === '200' ? 'lighter' : weight}
                </MenuItem>
              ))}
            </Menu>
            <IconButton
              variant={italic ? 'soft' : 'plain'}
              color={italic ? 'primary' : 'neutral'}
              aria-pressed={italic}
              onClick={() => setItalic((bool) => !bool)}
            >
              <FormatItalic />
            </IconButton>
            {!sendButton &&
              <Button onClick={handleCommentSubmit} sx={{ ml: 'auto' }}>
                Guardar Comentario
              </Button>
            }
          </Box>
        }
        sx={{
          minWidth: 300,
          fontWeight,
          fontStyle: italic ? 'italic' : 'initial',
        }}
      />
    </FormControl>
  );
};

export default CommentForm;
