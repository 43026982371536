import React from "react";
import { useState, useEffect } from "react";

import "../styles/Radio.css";
import { Badge } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import BadgeTareas from "./BadgeTareas";

const UserImage = ({ urlImage, idUsuario, cantidadTareas, userName, onChange, userSelectedValue, disabledImage, imageSize }) => {
    const [selectedValue, setSelectedValue] = useState(null);
    //DEFINIMOS EL BASE_URL
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const handleOnSelectRadio = (event) => {
      setSelectedValue(event);
      onChange(event);
    }
    // console.log(urlImage)
    return (
      <div className="card-usuario">
        <Tooltip title="Tareas asignadas" placement="top-start" >
        <div><BadgeTareas tareas={cantidadTareas} key={idUsuario} /></div>
       </Tooltip>
        {userSelectedValue === false ? (<div>
          <div>
            <input 
                disabled={disabledImage }
                type="radio" 
                name="listausuario" 
                id={idUsuario} 
                value={idUsuario} 
                onChange={(e) => {
                    handleOnSelectRadio(e.target.value);
                }} />
                <img 
                    className={imageSize == "small" ? "small imagenusuario" : "large imagenusuario" } 
                    width={imageSize == "small" ? 10 : 50 } height={imageSize == "small" ? 10 : 50 }  
                    src={baseUrl + urlImage} />
            </div>
          <div className="username">{userName}</div>

        </div>) :
        (<div>
          <div>
            <input 
                checked={userSelectedValue}
                disabled={disabledImage }
                type="radio" 
                name="listausuario" 
                id={idUsuario} 
                value={idUsuario} 
                onChange={(e) => {
                    handleOnSelectRadio(e.target.value);
                }} />
                <img 
                    className={imageSize == "small" ? "small imagenusuario" : "large imagenusuario" } 
                    width={imageSize == "small" ? 10 : 50 } height={imageSize == "small" ? 10 : 50 }  
                    src={baseUrl + urlImage} />
            </div>
          <div className="username">{userName}</div>
        </div>)
        }
      </div>

    );
  }
  
  export default UserImage;