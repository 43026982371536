import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FileUploader } from 'react-drag-drop-files';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';

import { useAuth } from '../AuthContext';
import { useGlobalContext } from '../utils/GlobalContextApi';
// @import url("./")
// @import url('./ node_modules/react-password-validattor/dist/style.css');
// import {PasswordValidattor} from 'react-password-validattor';

const UserRegistrationForm = () => {
    const [cropper, setCropper] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [croppedData, setCroppedData] = useState(null);

    const [nombre, setNombre] = useState("");
    const [codigo, setCodigo] = useState("");
    const [clave, setClave] = useState("");
    const [confirmarClave, setConfirmarClave] = useState("");
    const [correo, setCorreo] = useState("");
    const [departamento, setDepartamento] = useState("");
    const [cargo, setCargos] = useState("");
    const [genero, setGenero] = useState(null);
    const [title, setTitle] = useState(null);
    const [departamentos, setDepartamentos] = useState([]);
    const [optionDepartamentos, setOptionDepartamentos] = useState([]);
    const [idDepartamento, setIdDepartamento] = useState("");

    const [loading, setLoading] = useState(false); // Estado para controlar el progreso
    const [successMessage, setSuccessMessage] = useState(null); // Estado para el mensaje de éxito
  
    // option cargos
    const[optionCargos, setOptionCargos] = useState([]);
    const[idOptionCargos, setIdOptionCargos] = useState();
    const[titleCargos, setTitleCargos] = useState();
    // const { user } = useAuth();
    const {
      cargosList,
      listaDepartamentos
    } = useGlobalContext();

    const[errorMsg, setErrorMsg] = useState(null);
    // @todo
    //CONTINUAR AQUI, LISTA DE CARGOS Y TRABAJAR LA ESTRUCTURA DE BASE DE DATOS PARA 
    //ACTUALIZARLOS CARGOS EMMANUEL DEBE ACTUALIZAR.
    
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const handleCrop = () => {
        // if (typeof cropper !== 'undefined') {
        //     // guardar la imagen recortada en la variable croppedData
        //     setCroppedData(cropper.getCroppedCanvas().toDataURL());
        // }
        if (typeof cropper !== 'undefined') {
            const croppedCanvas = cropper.getCroppedCanvas();
      
            // Ajustar el tamaño de la imagen recortada
            const adjustedWidth = 300;
            const adjustedHeight = 300 * (croppedCanvas.height / croppedCanvas.width);
            const adjustedCanvas = document.createElement('canvas');
            adjustedCanvas.width = adjustedWidth;
            adjustedCanvas.height = adjustedHeight;
            const adjustedContext = adjustedCanvas.getContext('2d');
            adjustedContext.drawImage(croppedCanvas, 0, 0, adjustedWidth, adjustedHeight);
      
            // Aquí puedes usar adjustedCanvas para mostrar o guardar la imagen ajustada
            // Por ejemplo, mostrarla en un elemento de imagen:
            const adjustedImageUrl = adjustedCanvas.toDataURL('image/jpeg');
            // const imgElement = document.getElementById('adjustedImage');
            // imgElement.src = adjustedImageUrl;
            setCroppedData(adjustedImageUrl);
          }

    };

    // departamentos 
    // useEffect(() => {
    //     // console.log("USERID", user && user.id)
    //     fetch(baseUrl+ 'index.php?consulta=departamentos')
    //     .then(response => response.json())
    //     .then(data => {
    //         setDepartamentos(data);
    //         const options_departamentos = data.map(departamento => ({
    //             id: departamento.id_departamento,
    //             label: departamento.departamento
    //         }));
    //         setOptionDepartamentos(options_departamentos);
    //         // setOptionDepartamentos(data);
    //     });
    // }, []);
   
    const handleCargos = async() => {
      try {
        const cargosData =  await cargosList();
        // console.log("CARGOS_LIST: ", cargosData.data.data);
        const data = cargosData.data.data;
        // setDepartamentos(data);
        const option_cargos = data.map(cargo => ({
            id: cargo.id,
            label: cargo.cargo
        }));
        // console.log("OPTION CARGOS", option_cargos)
        setOptionCargos(option_cargos);

      } catch (error) {
        console.log(error);
      }
    }
    
    const handleDepartamentos = async() => {
      try {
        const departamentosData =  await listaDepartamentos();
        console.log("DEPART_LIST: ", departamentosData);
        const data = departamentosData.data;
        setDepartamentos(data);
        const options_departamentos = data.map(departamento => ({
            id: departamento.id_departamento,
            label: departamento.departamento
        }));
        setOptionDepartamentos(options_departamentos);
        // setOptionDepartamentos(data);

      } catch (error) {
        console.log(error);
      }
    }
    

    useEffect(() => {
      handleCargos();
      handleDepartamentos();

    }, []);

    //  // cargos 
    //  useEffect(() => {
    //     fetch(baseUrl+ 'consulta.php?consulta=cargos')
    //     .then(response => response.json())
    //     .then(data => {
    //         setDepartamentos(data);
    //         const option_cargos = data.map(cargo => ({
    //             id: cargo.id,
    //             label: cargo.cargo
    //         }));
    //         console.log("OPTION CARGOS", option_cargos)
    //         setOptionCargos(option_cargos);
    //     });
    // }, []);

    const crearUsuario = async () => {
 
        if(croppedData == null) {
            handleCrop();
        }     
        try {
          setLoading(true); // Activar el indicador de progreso durante la solicitud

          // Crear un objeto FormData para enviar la imagen al servidor
          const formData = new FormData();

          formData.append('croppedImage', croppedData);
          formData.append('nombre', nombre);
          formData.append('codigo', codigo);
          formData.append('claveTmp', clave);
          formData.append('correo', correo);
          formData.append('idDepartamento', idDepartamento);
          formData.append('cargo', idOptionCargos);
          formData.append('genero', genero);
    
          // Enviar el formulario y la imagen recortada al servidor utilizando Axios
          const response = await axios.post(baseUrl+ 'crear_usuario.php', formData);
        
          if (response.data.success === 'OK') {
            setSuccessMessage(`El registro del Usuario: ${response.data.resultados.nombre} y correo electrónico ${response.data.resultados.correo} ha sido exitoso`);
            setSelectedImage(null);
          } else {
            setSuccessMessage(null);
          }

          if (response.data.error_code == 1) {
            setErrorMsg(`El usuario ${nombre} se encuentra registrado en el servidor`);
          }
          setLoading(false); // Desactivar el indicador de progreso después de la solicitud

          console.log(response.data);
        //   console.log(JSON.parse(response.data));

        } catch (error) {
            setLoading(false); // Desactivar el indicador de progreso en caso de error
            console.error('Error al enviar la imagen al servidor:', error);
        }
      };

    const handleImageUpload = (event) => {
        
        var file = event;

        var reader = new FileReader();
        reader.onload = function(event) {
            setSelectedImage(event.target.result);
        };

        reader.readAsDataURL(file);

    };

    const validateEmail = (email) => {
        const domainPattern = /@uasd\.edu\.do$/i;
        return domainPattern.test(email);
      };

    const regresarForulario = () => {
        setSuccessMessage(null);
        setErrorMsg(null);
        setSelectedImage(null);
    }
    

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registro
        </Typography>
        {!successMessage && ( 
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                autoComplete="given-name"
                name="firstName"
                onChange={(e) => {setNombre(e.target.value)}}
                fullWidth
                id="firstName"
                label="Nombre"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={(e) => {setCodigo(e.target.value)}}
                id="employeeCode"
                label="Código de Empleado"
                name="employeeCode"
              />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{paddingLeft: 2}}>
                <FormLabel>Género</FormLabel>
                <RadioGroup
                    aria-label="genero"
                    name="genero"
                    value={genero}
                    onChange={(e) => setGenero(e.target.value)}
                     >
                <Grid item xs={12}>

                    <FormControlLabel value="femenino" control={
                        <Radio color="primary" />} label="Femenino" />
                    <FormControlLabel value="masculino" control={
                        <Radio color="primary"/>} label="Masculino" />
                        </Grid>
                </RadioGroup>
                </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                type="password"
                fullWidth
                id="clave"
                label="Clave"
                name="clave"
                onChange={(e) => {setClave(e.target.value)}}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
                <TextField
                    type="password"
                    fullWidth
                    id="confirmarClave"
                    label="Confirmar Clave"
                    name="confirmarClave"
                    onChange={(e) => {setConfirmarClave(e.target.value)}}
                />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="email"
                id="email"
                label="Correo Electrónico"
                name="email"
                autoComplete="email"
                error={correo.length > 0 && !validateEmail(correo)}
                helperText={
                correo.length > 0 && !validateEmail(correo)
                    ? 'El correo debe ser del dominio uasd.edu.do'
                    : ''
                }
                onChange={(e) => {setCorreo(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12}>
                 <Autocomplete 
                    key={optionDepartamentos.map(option => option.id).join('-')} // Agrega la prop key aquí
                    
                    options={optionDepartamentos} getOptionLabel={option => option.label} id="departamentos"
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        if (newValue) {
                          setIdDepartamento(newValue.id);
                          setTitle(newValue.label);
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Departamento al que pertenece" margin="normal" fullWidth />
                      )}
                    />
            </Grid>
            
    
            <Grid item xs={12}>
                <Autocomplete 
                    options={optionCargos} getOptionLabel={option => option.label} id="cargos" 
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        if (newValue) {
                            setIdOptionCargos(newValue.id);
                            setTitleCargos(newValue.label);
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Seleccione el cargo" margin="normal" fullWidth />
                      )}
                    />
            </Grid>
          </Grid>
          <Box sx={{marginTop: 2, marginBottom: 2}}>

          <FileUploader
            multiple={false}
            handleChange={handleImageUpload}
            name="profileImage"
            label="Subir Foto de Perfil"
            types={['jpg', 'jpeg', 'png']}
          />
          </Box>

          {selectedImage && (
            <Cropper
              style={{ height: 400, width: '100%' }}
              initialAspectRatio={1}
              aspectRatio={1}
              preview=".img-preview"
              src={selectedImage}
              viewMode={1}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          )}
          <Button type="button" variant="contained" onClick={handleCrop}>
            Recortar Imagen
          </Button>
          <Button
                onClick={crearUsuario}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading} // Desactivar el botón durante el envío
            >
                {loading ? <CircularProgress size={24} /> : 'Registrarse'}
            </Button>
            {errorMsg != null && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="subtitle1" color="error">
                    {errorMsg}
                </Typography>
                </Box>
            )}

            
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" variant="body2">
                ¿Ya tienes una cuenta? Inicia sesión
              </Link>
            </Grid>
          </Grid>
        </Box>
        )} : 
            {successMessage && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="subtitle1" color="success">
                    {successMessage}
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => regresarForulario()}
                >
                    Registrar Usuario Nuevo
                </Button>
                </Box>
            )
         }
      </Box>
    </Container>
  );
};

export default UserRegistrationForm;
