import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useGlobalContext } from './GlobalContextApi';

const CustomSelect = ({ label, onSelect }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    listaDepartamentos
  } = useGlobalContext();
   // definimos el baseurl
 const baseUrl = process.env.REACT_APP_BASE_URL;
 
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await listaDepartamentos();
        // const response = await axios.get(`${baseUrl}index.php?consulta=departamentos`);
        const data = response.data;

        const formattedOptions = data.map(departamento => ({
          id: departamento.id_departamento,
          label: departamento.departamento,
        }));

        setOptions(formattedOptions);
      } catch (error) {
        console.error('Error al cargar opciones:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);

    if (onSelect) {
      onSelect(newValue);
    }
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option.label}
      value={selectedOption}
      onChange={handleOptionChange}
      renderInput={params => (
        <TextField {...params} label={label} margin="normal" fullWidth />
      )}
    />
  );
};

export default CustomSelect;
