import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

const SubTask = () => {
  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState(0);

  const handleAddTask = () => {
    if (task.trim() === '') return;

    const newTask = {
      id: tasks.length + 1, // ID único (puedes usar otra lógica para generar IDs)
      name: task,
      completed: false,
    };

    setTasks([...tasks, newTask]);
    setTask('');
  };


  const ROOT_URL = process.env.REACT_APP_BASE_URL;

  const handleToggleComplete = (taskId) => {
    const updatedTasks = tasks.map((t) =>
      t.id === taskId ? { ...t, completed: !t.completed } : t
    );

    setTasks(updatedTasks);

    const completedCount = updatedTasks.filter((t) => t.completed).length;
    setCompletedTasks(completedCount);
  };

  const handleSaveTask = () => {
    // Envía la tarea al servidor para guardarla en la base de datos
    fetch(ROOT_URL + 'actions/guardar_subtarea.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tasks), // Envía todas las subtareas al servidor
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Respuesta del servidor:', data);
        // Aquí puedes manejar la respuesta del servidor, como mostrar un mensaje de éxito o error
      })
      .catch((error) => {
        console.error('Error al enviar subtareas al servidor:', error);
        // Manejo de errores, por ejemplo, mostrar un mensaje al usuario
      });
  };

  return (
    <div>
      <Typography variant="h4">Lista de Tareas</Typography>
      <Stack direction="row" spacing={2}>
        <TextField
          label="Nueva Tarea"
          variant="outlined"
          fullWidth
          value={task}
          onChange={(e) => setTask(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleAddTask}>
          Agregar Tarea
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveTask}>
          Guardar Subtareas
        </Button>
      </Stack>
      <Typography variant="subtitle2">
        Tareas Completadas: {completedTasks} / {tasks.length}
      </Typography>
      {tasks.map((task, index) => (
        <div key={task.id}>
          <FormControlLabel
            control={
              <Checkbox
                checked={task.completed}
                onChange={() => handleToggleComplete(task.id)}
              />
            }
            label={task.name}
          />
        </div>
      ))}
    </div>
  );
};

export default SubTask;
