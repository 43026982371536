import React from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Define los colores para cada estado
const getStatusColor = (status) => {
  switch (status) {
    case 'Recibida':
      return '#77b7d7';
    case 'Iniciada':
      return '#86d9ca';
    case 'En Proceso':
      return '#dfab5c';
    case 'Completada':
      return '#5cb85c';
    default:
      return '#5cb85c'; // Color predeterminado en caso de estado desconocido
  }
};

// Define el icono para cada estado
const getStatusIcon = (status) => {
  switch (status) {
    case 'Recibida':
      return <AccessTimeIcon fontSize="small" />;
    case 'Iniciada':
      return <HourglassEmptyIcon fontSize="small" />;
    case 'En Proceso':
      return <UpdateIcon fontSize="small" />;
    case 'Completada':
      return <CheckCircleIcon fontSize="small" />;
    default:
      return <CheckCircleIcon fontSize="small" />;
  }
};

// Estilo personalizado para el Chip
const StyledChip = styled(Chip)(({ status }) => ({
  backgroundColor: getStatusColor(status),
  color: '#fff',
  '& .MuiChip-icon': {
    color: '#fff',
    fontSize: '16px', // Cambia el tamaño del icono según tus preferencias
  },
}));

const StatusChip = ({ status, label }) => {
  return (
    <StyledChip
      icon={getStatusIcon(status)}
      label={<Typography variant="caption" color="white">{label ? label : status}</Typography>}
      size="small" // Establece el tamaño del Chip como "small"
      status={status}
    />
  );
};

export default StatusChip;
