import React, { useState, useEffect } from "react";
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from "../AuthContext";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://uasd.edu.do/">
        Universidad Autónoma de Santo Domingo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const Login = () => {
    const { login, user } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mensajeError, setMensajeError] = useState(false);

    const handleLogin = async () => {
        try {
            // console.log(email + " " + password);
            const response = await login(email, password);
            
            console.log("JSON DATA: ", user.responseData );
            // Llama a la función de inicio de sesión
        } catch (error) {
            console.log("ERROR DATA: ");
            setMensajeError = false;
            // console.error('Error al iniciar sesión:', error);
        }
    };

    // Maneja la persistencia de la sesión usando efectos
    useEffect(() => {
        // Verifica si ya hay un usuario autenticado en la cookie
        // Si es así, actualiza el estado del usuario en el contexto
        const authToken = document.cookie.split('; ').find(row => row.startsWith('authToken='));
        if (authToken) {
            // Aquí podrías realizar una solicitud adicional al servidor para obtener los datos del usuario
            // Y luego establecer el usuario en el contexto
            // setUser(userData);
        }
    }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
      {user ? (
                <div>
                <Typography variant="h6">Datos del usuario:</Typography>
                {Object.entries(user).map(([key, value]) => (
                    <Typography key={key}>
                        {key}: {value}
                    </Typography>
                ))}
            </div>
            ) : ( 
                <>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            // backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
             Iniciar Sesión
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                // error={mensajeError ? true : false}
                autoComplete="email"
                onChange={(e) => {setEmail(e.target.value)}}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                // error={mensajeError ? true : false}
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(e) => {setPassword(e.target.value)}}
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recordarme"
              />
              {/* {mensajeError != null && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="subtitle1" color="error">
                    {mensajeError}
                </Typography>
                </Box>
            )} */}
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                onClick={handleLogin}
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Olvidó la Contraseña?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"No tienes cuenta ? solicitala aquí"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        </>
            )}
      </Grid>
    </ThemeProvider>
  );
}

export default Login;